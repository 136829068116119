import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getToken,
  getTokenExpireIn,
  getTokenFetchTime,
} from '../../../../selectors/authSelectors';
import LoadingView from '../../../../components/LoadingView';

import {
  tokenRefreshRequired,
  refreshToken,
} from '../../../../utils/myAxaFetchWithToken';

import './styles.css';
// import _ from 'lodash';
// import { putCustState, fetchCustState } from '../../../../actions/userActions';
import { stringFormatter } from '../../../../utils';
// import env from '../../../../env';
import minimizeIcon from '../../assests/images/minimize-icons.svg';
import { getChatbotUrl } from '../../selectors/configSelectors';
import headerRectangleImg from '../../assests/images/rectangle.svg';
// import { goto } from '../../../../actions/historyActions';
// import EmmaSmile from '../../assests/images/emma-v-2-b-smile-fullcrop-glasses-hr-rgb.png'
// import EmmaSmile2x from '../../assests/images/emma-v-2-b-smile-fullcrop-glasses-hr-rgb@2x.png'
import { t } from '../../../../locales';
import { ngClass } from '../../../../utils';
// import browserHistory from '../../../../stores/browserHistory';
// import { resolvePublicAssets, isExternalAssets } from '../../../../utils';
import { getAvators } from '../../../../selectors/configSelectors';
import base64url from 'base64url';
import { History } from 'history';
import browserHistory from '../../../../stores/browserHistory';

interface ChatbotViewProps {
  token: string;
  tokenExpireIn: number;
  tokenFetchTime: string;
  //customer: string,
  hideChat: () => void;
  chatbotUrl: string;
  isShowingChat: boolean;
  avator: string;
  history?: History;
}

interface ChatbotViewState {
  tokenRefreshing: boolean;
  isChatLoading: boolean;
  firstEntryPageId: string;
}

class ChatbotView extends Component<ChatbotViewProps, ChatbotViewState> {
  iframe?: HTMLIFrameElement;
  refreshTokenWorkerRunning = false;
  refreshTokenWorkerTimer?: NodeJS.Timeout;
  navigationChangeUnsubscriber: any;
  firstEntryPageId?: string;
  //emmaInstanceId: Array<string> = [];
  constructor(props: ChatbotViewProps) {
    super(props);
    this.state = {
      tokenRefreshing: false,
      isChatLoading: true,
      firstEntryPageId: '',
    };
  }

  componentDidMount() {
    this.navigationChangeUnsubscriber = browserHistory.listen(
      (location, action) => {
        const { chatbotUrl } = this.props;
        if (this.iframe && this.iframe.contentWindow && chatbotUrl) {
          const url = new URL(chatbotUrl);
          const pageId = base64url.encode(
            location.pathname.replace(/^\/([en|zh])+\/emma/gi, ''),
          );
          console.log('chatbot location change', pageId, url);
          this.iframe.contentWindow.postMessage(
            JSON.stringify({
              action: '@EMMA_JS/CHATBOT_PAGEID_UPDATE',
              payload: { pageId },
            }),
            url.origin,
          );
        }
      },
    );
  }

  componentWillUnmount() {
    this.navigationChangeUnsubscriber && this.navigationChangeUnsubscriber();
  }

  getSrc() {
    const hrefUrl = new URL(window.location.href);
    let result = hrefUrl.searchParams.get('emmaJsTester');

    if (!result) {
      const { chatbotUrl, isShowingChat } = this.props;
      const { firstEntryPageId } = this.state;
      // console.log('pageId', window.location.pathname.replace(/^\/([en|zh])+\/emma/gi, ''))
      let page_id = firstEntryPageId;
      if (!firstEntryPageId && isShowingChat) {
        page_id = base64url.encode(
          window.location.pathname.replace(/^\/([en|zh])+\/emma/gi, ''),
        );
        this.setState({ firstEntryPageId: page_id });
      }
      result =
        stringFormatter(chatbotUrl, {
          buildNumber: `10200010`,
        }) + `&page_id=${page_id}`;
      // });
    }
    return result;
  }

  refreshTokenWorkerImpl = (): Promise<void> => {
    const { refreshTokenWorkerRunning } = this;

    return this.refreshTokenIfRequired().then(() => {
      return new Promise((res) => {
        this.refreshTokenWorkerTimer = setTimeout(() => {
          res();
        }, 5 * 1000);
      });
    });
  };
  refreshTokenWorker = (): Promise<void> =>
    this.refreshTokenWorkerImpl().then(() =>
      this.refreshTokenWorkerTimer
        ? this.refreshTokenWorker()
        : Promise.resolve(),
    );

  refreshTokenIfRequired(): Promise<void> {
    return new Promise((res) => {
      const { tokenRefreshing } = this.state;

      if (!tokenRefreshing && tokenRefreshRequired()) {
        this.setState({ tokenRefreshing: true }, () => {
          refreshToken().then(() => {
            this.setState({ tokenRefreshing: false }, () => res());
          });
        });
      } else {
        res();
      }
    });
  }

  // componentWillUnmount(){
  //   window.removeEventListener("message", this.windowMessageListener);
  // }

  componentWillReceiveProps(nextProps: ChatbotViewProps) {
    if (nextProps.token != this.props.token) {
      const emmaJsAuth = JSON.stringify({
        action: '@EMMA_JS/AUTH',
        payload: {
          token: nextProps.token,
          expiresIn: nextProps.tokenExpireIn,
          fetchTime: nextProps.tokenFetchTime,
        },
      });
      //this.postMessage(emmaJsAuth);
    }
  }

  // postMessage(message: string) {

  //   if(this.iframe && this.iframe.contentWindow){
  //     this.iframe.contentWindow.postMessage(message, `*`);
  //   }
  // }

  // parseEmmaJsMessage(event: MessageEvent){
  //   if(this.iframe && this.iframe.contentWindow && event.source && this.iframe.contentWindow === event.source){
  //     try {
  //       const message = JSON.parse(event.data);
  //       if (message.action && message.action.indexOf('@EMMA_JS/') == 0) {
  //         return message;
  //       }
  //     }catch(err){
  //       return undefined;
  //     }
  //   }
  //   return undefined;
  // }

  // handleEmmaJsMessage(event: MessageEvent) {

  //   const message = this.parseEmmaJsMessage(event);

  //   if(message){

  //     if (message.action == '@EMMA_JS/READY') {

  //       this.emmaInstanceId.push(message.instanceId);

  //       const {
  //         token, tokenExpireIn, tokenFetchTime
  //       } = this.props;

  //       const emmaJsResponse = JSON.stringify({
  //         action: '@EMMA_JS/RESPONSE',
  //         payload: {
  //           messageId: message.messageId,
  //           status: 'OK',
  //           body: {
  //             instanceId: this.emmaInstanceId,
  //             auth: {
  //               token,
  //               expiresIn: tokenExpireIn,
  //               fetchTime: tokenFetchTime,
  //             },
  //             deviceInfo: {
  //               platformOS: window.navigator.appVersion,
  //               userAgent: window.navigator.userAgent,
  //               timezone: new Date().getTimezoneOffset(),
  //             },
  //             env: {
  //               urlScheme: `${getBaseUrl()}/`,
  //               version: env.WEBAPP_VERSION,
  //               build: env.WEBAPP_VERSION
  //             }
  //           },
  //         },
  //       });
  //       this.postMessage(emmaJsResponse);
  //     }

  //     if (message.action === '@EMMA_JS/REFRESH_TOKEN') {
  //       refreshToken().then(() => {
  //         const emmaJsResponse = JSON.stringify({
  //           action: '@EMMA_JS/RESPONSE',
  //           payload: {
  //             action: message.action,
  //             messageId: message.messageId,
  //             status: 'OK',
  //           },
  //         });
  //         this.postMessage(emmaJsResponse);
  //       });
  //     }

  //     if (message.action === '@EMMA_JS/GO_TO') {
  //       const payload = _.get(message, `payload`, { url: `` });
  //       let { url } = payload;
  //       if (url) {
  //         goto(url);
  //       }
  //     }

  //     if(message.action === '@EMMA_JS/RESET_NAV'){
  //       browserHistory.push('/home');
  //     }

  //     if (message.action === '@EMMA_JS/SET_CUST_STATE') {
  //       const payload = _.get(message, `payload`, {
  //         key: ``,
  //         value: ``
  //       });
  //       putCustState(payload.key, payload.value, `emma`);
  //     }

  //     if (message.action === '@EMMA_JS/GET_CUST_STATE') {

  //       const payload = _.get(message, `payload`, {
  //         key: ``
  //       });

  //       fetchCustState(payload.key, `emma`).then(value => {
  //         const emmaJsResponse = JSON.stringify({
  //           action: '@EMMA_JS/RESPONSE',
  //           payload: {
  //             action: message.action,
  //             messageId: message.messageId,
  //             status: 'OK',
  //             body: value,
  //           },
  //         });
  //         this.postMessage(emmaJsResponse);
  //       })
  //     }
  //   }
  //   return false;
  // }

  // windowMessageListener = (e:MessageEvent) => {
  //   this.handleEmmaJsMessage(e);
  // }

  // componentDidMount() {
  //   window.addEventListener('message', this.windowMessageListener);
  // }

  hideLoading = () => {
    this.setState({ isChatLoading: false });
  };

  render() {
    const src = this.getSrc();
    const { hideChat, isShowingChat, avator } = this.props;
    const { tokenRefreshing, isChatLoading } = this.state;
    const toRender = [];

    if (!tokenRefreshing) {
      toRender.push(
        <iframe
          width="100%"
          height="100%"
          frameBorder="0"
          marginHeight={0}
          marginWidth={0}
          className={`chatbot-iframe`}
          key={`iframe`}
          src={src}
          ref={(ref) => (this.iframe = ref || undefined)}
          onLoad={this.hideLoading}
        />,
      );
    }

    if (tokenRefreshing || isChatLoading) {
      toRender.push(<LoadingView key={`LoadingView`} />);
    }

    toRender.push(
      <img
        key={`closeButton`}
        className="chatbot-close-button"
        src={minimizeIcon}
        onClick={hideChat}
        data-enable-ga
        data-ga-type="button"
      />,
    );

    return (
      <div
        className={ngClass(
          {
            opened: isShowingChat,
          },
          'chatbot-view-container',
        )}
      >
        <div className="header-chatbot-web-img">
          <img src={headerRectangleImg} />
          <div className="emma-smile-container">
            <span className="emma-smile-text">{t('CHAT_TITLE')}</span>
          </div>
        </div>
        {toRender}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const token = getToken(state);
  const tokenExpireIn = getTokenExpireIn(state);
  const tokenFetchTime = getTokenFetchTime(state);
  const chatbotUrl = getChatbotUrl(state);
  const avators = getAvators(state);
  return {
    token,
    tokenExpireIn,
    tokenFetchTime,
    chatbotUrl,
    avator: avators && avators.chatBotHeaderIcon,
  };
};

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ChatbotView);
