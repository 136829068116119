import React, { Component, ComponentClass } from 'react';
import { connect, useDispatch } from 'react-redux';
import { isApp, parseJSON } from '../../../../utils';
import { getAvators } from '../../../../selectors/configSelectors';
import { getLanguage } from '../../../../selectors/userSelectors';
import { getToken } from '../../../../selectors/authSelectors';
import { getChatbotUrl, getHideAvatar } from '../../selectors/configSelectors';
// import SideBarCallCentre from "../SideBarCallCentre";
import ChatbotButton from '../../components/ChatbotButton';
import SpeechPopUp from '../../components/SpeechPopUp/index';
import {
  getSpeechBubbleContent,
  randomWithProbability,
} from '../../components/SpeechPopUp/SpeechPopUpUtils';
// import FloatingMenu from '../../components/FloatingMenu';
import { UnregisterCallback } from 'history';
import {
  fetchHasVhisFlag,
  recordCloseSpeechBubble,
} from '../../actions/chatbotAction';
import {
  getSpeechBubbleList,
  getClosedSpeechBubble,
} from '../../selectors/configSelectors';
import browserHistory from '../../../../stores/browserHistory';

import './styles.css';

const AVATAR_BLACKLIST_URLS = [
  '/life/portal/policy-documents/pn/LMA#PolicyPackAcknowledgement$',
  '/life/portal/payment/[\\w]{1,}/[\\w]{1,}/terms-and-conditions',
];

interface ChatbotButtonContainerProps {
  lang: string;
  // onClick: () => void,
  token: string;
  chatbotUrl: string;
  showChat: () => void;
  isShowingChat: boolean;
  onFetchHasVhisFlag: () => void;
  hideAvatar: boolean;
  avators: any;
  speechBubbleList: Array<any>;
  closedSpeechBubble: Array<string>;
  onRecordCloseSpeechBubble: (
    sbId: string,
    closeMode: string,
    sbDetails: any,
  ) => void;
}

interface ChatbotButtonContainerState {
  isChatLoading: boolean;
  isShowingCallCentre: boolean;
  isShowingAgent: boolean;
  hideMenuWhenAsideOpened: boolean;
  hideOpenIconWhenToggled: boolean;
  pageId: string;
  hideAvatar: boolean;
  showingSpeechBubble: boolean;
  chatbotSpeechBubbleContent: any;
}

class ChatbotButtonContainer extends Component<
  ChatbotButtonContainerProps,
  ChatbotButtonContainerState
> {
  unregisterHistoryListener?: UnregisterCallback;
  navigationChangeUnsubscriber: any;
  constructor(props: ChatbotButtonContainerProps) {
    super(props);
    this.state = {
      isChatLoading: true,
      isShowingCallCentre: false,
      isShowingAgent: false,
      hideMenuWhenAsideOpened: true,
      hideOpenIconWhenToggled: true,
      pageId: '',
      hideAvatar: false,
      showingSpeechBubble: false,
      chatbotSpeechBubbleContent: {},
    };
    this.oneCloseSpeechBubble = this.oneCloseSpeechBubble.bind(this);
  }

  componentWillUnmount() {
    this.navigationChangeUnsubscriber && this.navigationChangeUnsubscriber();
  }

  componentDidMount() {
    // this.checkHash(window.location.hash);

    const { onFetchHasVhisFlag, hideAvatar } = this.props;
    this.setState({ hideAvatar: hideAvatar });
    if (onFetchHasVhisFlag) onFetchHasVhisFlag();

    this.navigationChangeUnsubscriber = browserHistory.listen(
      (location, action) => {
        this.setLatestPageIdAndHideAvatarIfNeeded();
      },
    );
    window.addEventListener('message', (event: any) => {
      const action: any = {};
      const isJson = parseJSON(event.data, action);
      if (!isJson) {
        Object.assign(action, event.data);
      }
      if (action) {
        switch (action.type) {
          case 'history':
            this.setLatestPageIdAndHideAvatarIfNeeded();
            break;
          default:
            return;
        }
      }
    });
    // listen LifeScreen.tsx line 289  document.dispatchEvent(new CustomEvent('popstate'...
    document.addEventListener('popstate', (event: any) => {
      const action: any = {};
      const isJson = parseJSON(event.detail, action);
      if (!isJson) {
        Object.assign(action, event.detail);
      }
      if (!action || !action.type || action.type !== 'POP_HISTORY') {
        return;
      }
      this.setLatestPageIdAndHideAvatarIfNeeded();
    });
    // in case LifeScreen custom event is not used anymore
    window.addEventListener('popstate', (event: any) => {
      this.setLatestPageIdAndHideAvatarIfNeeded();
    });
    this.setLatestPageIdAndHideAvatarIfNeeded();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.speechBubbleList?.length !==
        this.props.speechBubbleList?.length ||
      prevProps.closedSpeechBubble?.length !==
        this.props.closedSpeechBubble?.length
    ) {
      this.getSpeechBubbleContent();
    }
  }

  private setLatestPageIdAndHideAvatarIfNeeded() {
    setTimeout(() => {
      const pageId = this.getCurrentPageId();
      this.setState({
        pageId,
      });
      this.getSpeechBubbleContent();
      this.hideAvatarByBlackList();
    }, 0); // wait for 0s to wait till url changing action ran
  }

  getCurrentPageId() {
    return window.location.pathname
      .replace(/^\/([en|zh])+\/emma/gi, '')
      .toLowerCase();
  }

  hideAvatarByBlackList() {
    let isHide = false;
    for (let i = 0; i < AVATAR_BLACKLIST_URLS.length; i++) {
      const re = new RegExp(AVATAR_BLACKLIST_URLS[i]);
      if (re.test(window.location.href)) {
        isHide = true;
        break;
      }
    }
    this.setState({ hideAvatar: isHide });
  }

  // checkHash = (hash: string) => {
  //   if(hash && hash.indexOf('contactagent') > 0){
  //     this.showAgent();
  //   }
  // }

  // hideAgent = () => {
  //   this.setState({
  //     isShowingCallCentre: false,
  //     isShowingAgent: false
  //   });
  // }

  // showAgent = () => {
  //   this.setState({
  //     isShowingCallCentre: false,
  //     isShowingAgent: true
  //   });
  // }

  // hideCallCentre = () => {
  //   this.setState({
  //     isShowingCallCentre: false,
  //     isShowingAgent: false,
  //   });
  // }

  // showCallCentre = () => {
  //   this.setState({
  //     // isShowingChat: false,
  //     isShowingCallCentre: true,
  //     isShowingAgent: false,
  //   });
  // }
  oneCloseSpeechBubble(sbId: string, closeMode: string, sbDetails: any) {
    this.props.onRecordCloseSpeechBubble(sbId, closeMode, sbDetails);
    this.setState({
      showingSpeechBubble: false,
    });
  }

  render() {
    const { showChat, avators, lang } = this.props;
    const {
      isShowingCallCentre,
      isShowingAgent,
      hideMenuWhenAsideOpened,
      hideAvatar,
    } = this.state;

    const floatMenuClassName = 'floating-menu';
    let ChatbotButtonContainerClassName = 'chatbot-container';
    let sectionViewClassName = 'section';

    if (isShowingCallCentre || isShowingAgent) {
      sectionViewClassName += ' open';
      ChatbotButtonContainerClassName += ' open';
      if (hideMenuWhenAsideOpened) {
        ChatbotButtonContainerClassName += ' hideMenu';
      }
    }

    return (
      <div className="chatbot-module-chatbot-button-container">
        <div className={ChatbotButtonContainerClassName}>
          <div className={sectionViewClassName}>
            <div className="floating-menu-container">
              <div className={floatMenuClassName}>
                {/* <FloatingMenu>
                  <FloatingMenu.FloatingMenuItem className="" label="Call Centre" icon={callCentreIcon} action={this.showCallCentre} key="CallCentre" />
                </FloatingMenu> */}
                {!hideAvatar && (
                  <ChatbotButton
                    isShowing={true}
                    showChat={showChat}
                    avator={avators.chatBotHomeIcon}
                    avatorWithSpeechBubble={avators.chatBotIcon}
                    showingSpeechBubble={this.state.showingSpeechBubble}
                  />
                )}
                {!hideAvatar && this.state.showingSpeechBubble && (
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '0',
                      right: '0',
                      marginBottom: '78px',
                    }}
                  >
                    <SpeechPopUp
                      bannerContent={this.state.chatbotSpeechBubbleContent}
                      language={lang}
                      onClose={this.oneCloseSpeechBubble}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <SideBarCallCentre isShowing={isShowingCallCentre} hideSideBar={this.hideCallCentre} /> */}
        </div>
      </div>
    );
  }

  private getSpeechBubbleContent() {
    const { speechBubbleList, closedSpeechBubble } = this.props;
    if (!isApp() && speechBubbleList && speechBubbleList.length) {
      const chatbotSpeechBubbleContentList = getSpeechBubbleContent(
        speechBubbleList,
        this.state.pageId,
        true,
        closedSpeechBubble,
      );
      const foundSpeechBubbleDetailsForPageId =
        randomWithProbability(chatbotSpeechBubbleContentList) || {};
      if (
        !isApp() &&
        this.state.pageId &&
        foundSpeechBubbleDetailsForPageId &&
        foundSpeechBubbleDetailsForPageId.bubbleSwitch
      ) {
        const chatbotSpeechBubbleContent = foundSpeechBubbleDetailsForPageId;
        this.setState({
          showingSpeechBubble: true,
          chatbotSpeechBubbleContent,
        });
      } else {
        const chatbotSpeechBubbleContent = {};
        this.setState({
          showingSpeechBubble: false,
          chatbotSpeechBubbleContent,
        });
      }
    }
  }

  static mapStateToProps(state: any, ownProps: any) {
    const lang = getLanguage(state);
    const avators = getAvators(state);
    const speechBubbleList = getSpeechBubbleList(state);
    const closedSpeechBubble = getClosedSpeechBubble(state);

    return {
      lang,
      token: getToken(state),
      chatbotUrl: getChatbotUrl(state),
      hideAvatar: getHideAvatar(state),
      avators,
      speechBubbleList,
      closedSpeechBubble,
    };
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      // onClick: () => dispatch(signOut())
      onFetchHasVhisFlag: () => dispatch(fetchHasVhisFlag()),
      onRecordCloseSpeechBubble: (
        sbId: string,
        closeMode: string,
        sbDetails: any,
      ) => dispatch(recordCloseSpeechBubble(sbId, closeMode, sbDetails)),
    };
  }
}

export default connect(
  ChatbotButtonContainer.mapStateToProps,
  ChatbotButtonContainer.mapDispatchToProps,
)(ChatbotButtonContainer);
