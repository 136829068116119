import React, { Component } from 'react';
import { select } from '../../stores';
import { getToken } from '../../selectors/authSelectors';
import { getValidToken, isApp } from '../../utils/index';
import './styles.css';
import { resolveLocalizedText, resolvePublicAssets } from '../../utils';
import overlayIcon from '../../assets/images/icon-myprivileges-blue.png';
import { handleGaError } from '../../utils/generalGaEventAnalytics';
import { maskPN } from '@axa-asia/hk-emma-web-module/lib/ga-lib/MaskingUtil';
interface MyPrivilegesOverlayProps {
  setHaveReadNewFeature: () => void;
  MyPrivilegesOverlayModel: any;
  onClickShadow: () => void;
}

class MyPrivilegesOverlay extends Component<MyPrivilegesOverlayProps> {
  componentDidMount() {
    const tcWindow: any = window;
    const token = select(getToken);
    const aToken = getValidToken(token);
    if (aToken) {
      try {
        const tcWindow: any = window;
        tcWindow.dataLayer.push({
          event: 'screenView',
          page: '/emma-webportal/my-privileges-overlay',
          userId: aToken,
        });

      // HKEMMA-100917 - GA3 Decommission - Remove GA3
        // if (!isApp()) {
        //   tcWindow.tC.event.virtualPageview(this, {
        //     page: '/emma-webportal/my-privileges-overlay',
        //     AXAID: aToken,
        //   });
        // }
      } catch (e) {
        handleGaError(e);
      }
    }
  }

  onClickToGatc() {
    const tcWindow: any = window;
    const token = select(getToken);
    const aToken = getValidToken(token);
    try {
      tcWindow.dataLayer.push({
        event: 'betterMe',
        icon: 'My privileges overlay',
        overlayButton: 'show me',
        userId: aToken,
      });

      // HKEMMA-100917 - GA3 Decommission - Remove GA3
      // if (!isApp()) {
      //   tcWindow.tC.event.betterMe(this, {
      //     AXAID: aToken,
      //     icon: 'My privileges overlay',
      //     overlayButton: 'show me',
      //   });
      // }
    } catch (e) {
      handleGaError(e);
    }
  }

  render() {
    const { MyPrivilegesOverlayModel, setHaveReadNewFeature, onClickShadow } =
      this.props;
    const html = MyPrivilegesOverlayModel.messageHtml
      ? resolveLocalizedText(MyPrivilegesOverlayModel.messageHtml)
      : '';
    const overlayIcon = MyPrivilegesOverlayModel.image
      ? resolvePublicAssets(`assets/${MyPrivilegesOverlayModel.image}`).replace(
          '.jpg',
          '@3x.jpg',
        )
      : '';
    return (
      <div>
        <div
          className="myprivileges-overlay-background"
          onClick={() => {
            this.onClickToGatc();
            onClickShadow();
          }}
          data-enable-ga
          data-ga-type="button"
        ></div>
        <div
          className="myprivileges-overlay"
          onClick={() => {
            this.onClickToGatc();
            setHaveReadNewFeature();
          }}
          data-enable-ga
          data-ga-type="button"
        >
          <div className="myprivileges-overlay-content">
            <div className="myprivileges-overlay-out"></div>
            <div className="myprivileges-overlay-in">
              <div className="overlay-img">
                <img src={overlayIcon}></img>
              </div>
              <p
                className="overlay-title"
                dangerouslySetInnerHTML={{ __html: html }}
              ></p>
              <div
                className="overlay-show-me"
                onClick={(e) => {
                  e.stopPropagation();
                  this.onClickToGatc();
                  setHaveReadNewFeature();
                }}
                data-enable-ga
                data-ga-type="button"
              >
                <span>SHOW ME</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MyPrivilegesOverlay;
