export const isInsideBrowser = (() => typeof window !== 'undefined')();

export const isMobileBrowser = () => {
  if (!isInsideBrowser) return false;
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const isEmmaApp = (() => {
  if (!isInsideBrowser) return false;
  return /EMMA( [A-Za-z]+)?\/([0-9]+\.){3}[0-9]{8,10}/g.test(
    navigator.userAgent,
  );
})();

export const isTabletDevice = (() => {
  if (!isInsideBrowser) return false;
  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent,
    );
  return isTablet;
})();

export const platformStr = (() => {
  if (isEmmaApp) {
    return 'APP';
  } else if (isTabletDevice) {
    return 'TABLET';
  } else {
    return 'WEB';
  }
})();

export const deviceType = (() => {
  if (isEmmaApp) {
    return 'm'; // Mobile
  } else if (isTabletDevice) {
    return 't'; // Tablet
  } else {
    return 'd'; // Desktop
  }
})();
