import { getToken } from '../selectors/authSelectors';
import { select } from '../stores';
import { getCustomerApiUrl } from '../selectors/configSelectors';
import { getCustStateApiUrl } from '../selectors/configSelectors';
import { myAxaFetchWithToken } from '../utils/myAxaFetchWithToken';
import _ from 'lodash';

export const PUT_CUST_STATE_REQUEST = `PUT_CUST_STATE_REQUEST`;
export const PUT_CUST_STATE_SUCCESS = `PUT_CUST_STATE_SUCCESS`;

export const FETCH_CUST_STATE_REQUEST = `FETCH_CUST_STATE_REQUEST`;
export const FETCH_CUST_STATE_SUCCESS = `PUT_CUST_STATE_SUCCESS`;

export const FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMER_REQUEST';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE';

export const FETCH_LAUNCHPAD_DATA = 'FETCH_LAUNCHPAD_DATA';
export const FETCH_LAUNCHPAD_DATA_SUCCESS = 'FETCH_LAUNCHPAD_DATA_SUCCESS';

export const FETCH_HAS_SHOWN_LAUNCHPAD = 'FETCH_HAS_SHOWN_LAUNCHPAD';
export const ALREADY_SHOWN_LAUNCHPAD = 'ALREADY_SHOWN_LAUNCHPAD';
export const LAUNCH_TRY_NOW_CLICKED = 'LAUNCH_TRY_NOW_CLICKED';

export const fetchCustomerRequest = () => ({
  type: FETCH_CUSTOMER_REQUEST,
});

export const fetchCustomerSuccess = (customer: any) => ({
  type: FETCH_CUSTOMER_SUCCESS,
  customer,
});

export const fetchCustomerFailure = (error: any) => ({
  type: FETCH_CUSTOMER_FAILURE,
  error,
});

export const fetchLaunchpadData = () => ({
  type: FETCH_LAUNCHPAD_DATA,
});

export const fetchLaunchpadDataSuccess = (data: any) => ({
  type: FETCH_LAUNCHPAD_DATA_SUCCESS,
  data,
});

export const fetchCustState = (key: string, channel: string) => {
  const custApiUrl = select(getCustStateApiUrl);
  return myAxaFetchWithToken(
    `${custApiUrl}?channel=${encodeURI(channel)}&custKey=${encodeURI(key)}`,
    {
      method: 'GET',
    },
  )
    .then((res) => res.json())
    .then((data) => {
      return (data && data.length > 0 && data[0].custValue) || '{}';
    });
};

export const putCustState = (
  custKey: string,
  custValue: string,
  channel: string,
  featureName?: string,
) => {
  const custApiUrl = select(getCustStateApiUrl);
  const changedCustApiUrl = featureName
    ? `${custApiUrl}?featureName=${featureName}`
    : custApiUrl;
  return myAxaFetchWithToken(`${changedCustApiUrl}`, {
    method: 'POST',
    body: JSON.stringify({
      insertCustomerStateRequest: {
        channelCode: encodeURI(channel),
        custKey: encodeURI(custKey),
        custValue: encodeURI(custValue),
      },
    }),
  }).then((res) => res.status);
};

export const triggerTryNowButton = () => ({
  type: LAUNCH_TRY_NOW_CLICKED,
});

export const getLaunchpadState = () => {
  return {
    type: FETCH_HAS_SHOWN_LAUNCHPAD,
  };
};

export const setLaunchpadState = () => {
  return {
    type: ALREADY_SHOWN_LAUNCHPAD,
  };
};
