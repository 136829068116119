import React from 'react';
import { connect } from 'react-redux';
import { getPolicyNumberStart } from '../../actions/policyNumberActions';
import { getPolicyNumber } from '../../selectors/policyNumberSelector';

export const PN_EMPTY = 'PN_EMPTY';
export const PN_ROUTE_PLACEHOLDER = 'pn';

interface PNHocProps {
  policyNumber: string;
  params: any;
  getPolicyNumber: any;
}

interface PNHocState {}

/**
 * This HOC is required for a page when
 * 1) accessed by iFrame or App and
 * 2) page path has a policy number (e.g. :policyNumber)
 *
 * This HOC is to solve a sensitive data issue that PN cannot be put on URL
 */
export function withPNHoc(ComponentToWrap: any) {
  class PNHoc extends React.Component<any, PNHocState> {
    componentDidMount() {
      if (!this.props.policyNumber) {
        this.props.getPolicyNumber();
      }
    }

    render(): React.ReactNode {
      const { policyNumber } = this.props;
      const isProceed = policyNumber;

      // Override the route params
      const revisedProps = {
        ...this.props,
        match: {
          ...this.props.match,
          params: {
            ...this.props.match.params,
            policy_number: policyNumber,
            id: policyNumber,
          },
        },
      };

      if (isProceed) {
        return <ComponentToWrap {...revisedProps} />;
      }
      return null;
    }
  }

  const mapStateToProps = (state: any, ownProps: any) => {
    /** Keys of policy number in route parameter */
    const { policy_number: routePn } = ownProps.match.params;
    const { id: routeId } = ownProps.match.params;

    let policyNumber = '';
    if (
      (routePn && routePn === PN_ROUTE_PLACEHOLDER) ||
      (routeId && routeId === PN_ROUTE_PLACEHOLDER)
    ) {
      policyNumber = getPolicyNumber(state);
    } else if (routePn || routeId) {
      policyNumber = routePn || routeId;
    }

    return {
      policyNumber,
    };
  };

  const mapDispatchToProps = (dispatch: any) => ({
    getPolicyNumber: () => {
      dispatch(getPolicyNumberStart());
    },
  });

  const ConnectedHOC = connect(mapStateToProps, mapDispatchToProps)(PNHoc);

  return ConnectedHOC;
}
