import { EmmaFactory } from '@axa-asia/hk-emma-js';
import { getToken } from '../selectors/authSelectors';
import { select, store } from '../stores';
import { isApp, getValidToken, getPlatformStr } from './index';
import {
  setDisplayedButtonText,
  getDisplayedButtonText,
} from '@axa-asia/hk-emma-web-module/lib/ga-lib/GaPreviousScreenUtil';
import { maskPN } from '@axa-asia/hk-emma-web-module/lib/ga-lib/MaskingUtil';
import { MOB_PREFIX } from '../actions/appActions';
import {
  trackAcceptDisclaimer,
  trackCtaButton,
  trackDefaultPageViewGA4,
  trackScrollDepthGA4,
  trackModifyInsuredAccess,
  trackUpdateProfileContact,
  trackClickRemindInsured,
  trackDisplayError,
  trackBannerAction,
} from './firebaseAnalyticsEvents';
import { getScreenNameMapping } from '../../src/modules/google-analytics/selectors/homeSelector';
import { getScreenNameAsync } from '@axa-asia/hk-emma-web-module/lib/ga-lib/WebScreenNameMappingUtil';

export function getClientIdFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  const clientId = urlParams.get('clientId');
  return clientId;
}

export function getGa4ClientIdFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  const clientId = urlParams.get('ga4ClientId');
  return clientId;
}

export function getEnvFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  const env = urlParams.get('env');
  return env;
}

export function getAxaIdFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  const axaId = urlParams.get('axaId');
  return axaId;
}

export const getGaPagePath = (screenName?: string) => {
  const pageName = screenName || window.location.pathname;
  const convertedPageName = maskPN(pageName.replace(`/${MOB_PREFIX}`, ''));
  return convertedPageName;
};

/**
 * Translate screen path to screen name
 * @param screenPath
 * @returns
 */
const getScreenName = async (): Promise<string> => {
  const screenPath = window.location.pathname;
  const state = store.getState();
  const platformStr = getPlatformStr();
  const screenNameMapping = getScreenNameMapping(state);
  const screenName = (await getScreenNameAsync(
    screenPath,
    platformStr,
    screenNameMapping,
  )) as string;
  return screenName;
};

export const handleGaError = (e: any) => {
  const tcWindow: any = window;
  if (!isApp() && !tcWindow.tC) {
    console.warn('GA script is not loaded but event is triggered.');
  } else if ((!isApp() && tcWindow.tC) || isApp()) {
    console.error('GA event error', e);
  }
};

// #47 The scroll depth users scroll vertically and horizontally on the specified screens
export enum TrackScrollDepthDirection {
  VERTICAL = 'Vertical',
  HORIZONTAL = 'Horizontal',
}
export const trackScrollDepth = (
  ratio: number,
  direction: TrackScrollDepthDirection,
  screenName: string,
) => {
  try {
    const tcWindow: any = window;
    const event = 'trackScrollDepth';
    const token = select(getToken);
    const axaId = getValidToken(token);

    // GA3
    tcWindow.dataLayer.push({
      event,
      action: `${direction} - ${ratio}%`,
      screenName: maskPN(screenName),
      axaId,
    });

    // GA4
    trackScrollDepthGA4(screenName, direction, `${ratio}`);

    // HKEMMA-100917 - GA3 Decommission - Remove GA3
    // if (!isApp()) {
    //   tcWindow.tC.event.trackScrollDepth(window, {
    //     scrollDirection: direction,
    //     scrolldepthThreshold: `${ratio}%`,
    //     screenName,
    //     axaId,
    //   });
    // }
  } catch (e) {
    handleGaError(e);
  }
};

// #62 ga tagging for all CTA button/tab/link text
export const clickCTAButton = (
  event: any,
  displayedButtonText: string,
  screenName: any,
  AXAID: any,
) => {
  try {
    const MAX_LENGTH = 100;
    const trimmedBtnText = displayedButtonText.substring(0, MAX_LENGTH);

    const tcWindow: any = window;
    tcWindow.dataLayer.push({
      event,
      displayedButtonText: trimmedBtnText,
      screenName: maskPN(screenName),
      userId: AXAID,
    });
    trackCtaButton(trimmedBtnText, screenName);

    // HKEMMA-100917 - GA3 Decommission - Remove GA3
    // if (!isApp()) {
    //   tcWindow.tC.event.clickCTAButton(window, {
    //     displayedButtonText: trimmedBtnText,
    //     screenName,
    //     AXAID: AXAID,
    //   });
    // }
  } catch (e) {
    handleGaError(e);
  }
};

export const trackPreviousScreen = (
  event: any,
  displayedButtonText: string,
  previousScreenName: any,
  screenName: any,
  AXAID: any,
) => {
  try {
    const tcWindow: any = window;
    tcWindow.dataLayer.push({
      event: event,
      displayedButtonText: displayedButtonText,
      previousScreenName: maskPN(previousScreenName),
      screenName: maskPN(screenName),
      userId: AXAID,
    });

    // HKEMMA-100917 - GA3 Decommission - Remove GA3
    // if (!isApp()) {
    //   tcWindow.tC.event[event](window, {
    //     event: event,
    //     displayedButtonText: displayedButtonText,
    //     previousScreenName: previousScreenName,
    //     screenName: screenName,
    //     AXAID: AXAID,
    //   });
    // }
  } catch (e) {
    handleGaError(e);
  }
};

// GA callback for clickable elements
export const gaClickableElementCallback = (
  event: any,
  displayedButtonText: string,
  screenName: any,
) => {
  setDisplayedButtonText(displayedButtonText);
  try {
    // Retrieve axaid and the axaid must be accessed by the callback
    const token = select(getToken);
    const axaId = getValidToken(token);
    // TODO: different event use diff function
    if (event === 'clickCTAButton') {
      clickCTAButton(
        event,
        displayedButtonText,
        getGaPagePath(screenName),
        axaId,
      );
    }
  } catch (e) {
    handleGaError(e);
  }
};

export const logPreviousUrlCallback = (
  previousScreenName: string,
  currScreenName: string,
  currScreenPath: string,
) => {
  try {
    // Retrieve axaid and the axaid must be accessed by the callback
    const token = select(getToken);
    const axaId = getValidToken(token);
    const eventName = 'previousScreen';
    const maskedPreviousScreenName = maskPN(
      previousScreenName.replace('/mob', ''),
    );
    const maskedCurrScreenName = maskPN(currScreenName.replace('/mob', ''));

    //Deal with the browser button click case
    if (window.event && window.event.type === 'popstate') {
      setDisplayedButtonText('Back / Forward');
    }
    const displayedButtonText = getDisplayedButtonText();

    // Track popup display to trigger page view
    if (currScreenPath.includes('#') || previousScreenName.includes('#')) {
      trackDefaultPageView(currScreenPath);
    }

    trackPreviousScreen(
      eventName,
      displayedButtonText,
      maskedPreviousScreenName,
      maskedCurrScreenName,
      axaId,
    );

    trackDefaultPageViewGA4(
      maskedCurrScreenName,
      maskedPreviousScreenName,
      currScreenPath,
    );
  } catch (e) {
    handleGaError(e);
  }
};

/** KPI 65 - Number of profile avatar and name clicks */
export const trackProfileAvatar = () => {
  try {
    const tcWindow: any = window;
    const screenName = getGaPagePath();
    const format = 'avatar';
    const token = select(getToken);
    const AXAID = getValidToken(token);

    tcWindow.dataLayer.push({
      event: 'myprofile',
      format,
      screenName,
      userId: AXAID,
    });

    // HKEMMA-100917 - GA3 Decommission - Remove GA3
    // if (!isApp()) {
    //   tcWindow.tC.event.myprofile(window, {
    //     format,
    //     screenName,
    //     AXAID,
    //   });
    // }
  } catch (e) {
    handleGaError(e);
  }
};

/** KPI 65 - Number of profile avatar and name clicks */
export const trackProfileName = () => {
  try {
    const tcWindow: any = window;
    const screenName = getGaPagePath();
    const format = 'name';
    const token = select(getToken);
    const AXAID = getValidToken(token);

    tcWindow.dataLayer.push({
      event: 'myprofile',
      format,
      screenName,
      userId: AXAID,
    });

    // HKEMMA-100917 - GA3 Decommission - Remove GA3
    // if (!isApp()) {
    //   tcWindow.tC.event.myprofile(window, {
    //     format,
    //     screenName,
    //     AXAID,
    //   });
    // }
  } catch (e) {
    handleGaError(e);
  }
};

/** KPI 9 -  Number of times users accepted the Partner & Chatbot services disclaimer */
export enum TrackPartnerServiceDisclaimerIcon {
  INSURED_ACCESS = 'insuredAccess',
  EREGISTRATION = 'eRegistration',
  TEAM_CHALLENGE = 'teamChallenge',
  SENSELY = 'sensely',
  HEALTH_KEEPER = 'health-keeper',
  CUHK_TOUR_HEART = 'cuhktourheart',
  CONTACT_UPDATE = 'contactUpdate',
}
export const trackPartnerServiceDisclaimer = (
  icon: TrackPartnerServiceDisclaimerIcon | string,
) => {
  try {
    const tcWindow: any = window;
    const event = 'acceptDisclaimer';
    const token = select(getToken);
    const userId = getValidToken(token);

    // GA3
    tcWindow.dataLayer.push({
      event,
      icon,
      userId,
    });

    // GA4
    trackAcceptDisclaimer(icon);

    // HKEMMA-100917 - GA3 Decommission - Remove GA3
    // if (!isApp()) {
    //   tcWindow.tC.event.acceptDisclaimer(window, {
    //     icon,
    //     AXAID: userId,
    //   });
    // }
  } catch (e) {
    handleGaError(e);
  }
};

/** KPI 66 - Number of users click on the authorize and deauthorize buttons for the insured access */
export enum TrackInsuredAccessAction {
  AUTHORIZED = 'authorize',
  DEAUTHORIZED = 'deauthorize',
}
export const trackInsuredAccess = (
  action: TrackInsuredAccessAction,
  policyNumber: string,
) => {
  try {
    const tcWindow: any = window;
    const event = 'insuredAccess';
    const token = select(getToken);
    const userId = getValidToken(token);

    // GA3
    tcWindow.dataLayer.push({
      event,
      action,
      // policyNumber,
      userId,
    });
    // GA4
    trackModifyInsuredAccess(action, policyNumber);

    // HKEMMA-100917 - GA3 Decommission - Remove GA3
    // if (!isApp()) {
    //   tcWindow.tC.event.insuredAccess(window, {
    //     action,
    //     // policyNumber,
    //     AXAID: userId,
    //   });
    // }
  } catch (e) {
    handleGaError(e);
  }
};

/** KPI 67 - Number of successful updates on email, mobile and correspondence address, username and password */
export enum TrackUpdateContactSuccessContent {
  EMAIL = 'email',
  MOBILE = 'mobile',
  ADDRESS = 'correspondence address',
  USERNAME = 'username',
  PASSWORD = 'password',
}
export enum TrackUpdateContactSuccessAction {
  ADD = 'add',
  UPDATE = 'update',
}
export const trackUpdateContactSuccess = (
  content: TrackUpdateContactSuccessContent,
  action: TrackUpdateContactSuccessAction,
) => {
  try {
    const tcWindow: any = window;
    const event = 'updateContact';
    const screenName = getGaPagePath();
    const token = select(getToken);
    const userId = getValidToken(token);

    // GA3
    tcWindow.dataLayer.push({
      event,
      action,
      content,
      userId,
    });
    // GA4
    trackUpdateProfileContact(screenName, action, content);

    // HKEMMA-100917 - GA3 Decommission - Remove GA3
    // if (!isApp()) {
    //   tcWindow.tC.event.updateContact(window, {
    //     action,
    //     content,
    //     screenName,
    //     AXAID: userId,
    //   });
    // }
  } catch (e) {
    handleGaError(e);
  }
};

/** KPI 68 - Number of remind insured button clicks */
export enum TrackRemindInsuredAction {
  PENDING = 'pending',
  ACTIVATED = 'activated',
}
export const trackRemindInsured = (
  action: TrackRemindInsuredAction,
  policyNumber: string,
) => {
  try {
    const tcWindow: any = window;
    const event = 'remindInsured';
    const token = select(getToken);
    const userId = getValidToken(token);

    // GA3
    tcWindow.dataLayer.push({
      event,
      insuredAccessSystemStatus: action,
      // policyNumber,
      userId,
    });
    // GA4
    trackClickRemindInsured(action, policyNumber);

    // HKEMMA-100917 - GA3 Decommission - Remove GA3
    // if (!isApp()) {
    //   tcWindow.tC.event.remindInsured(window, {
    //     insuredAccessSystemStatus: action,
    //     // policyNumber,
    //     AXAID: userId,
    //   });
    // }
  } catch (e) {
    handleGaError(e);
  }
};

/** KPI 13 - Number of times users click on the blue "START NOW" button */
export const trackEnrolmentTimes = () => {
  try {
    const tcWindow: any = window;
    const event = 'healthKeeper';
    const token = select(getToken);
    const userId = getValidToken(token);

    tcWindow.dataLayer.push({
      event,
      userId,
    });

    // HKEMMA-100917 - GA3 Decommission - Remove GA3
    // if (!isApp()) {
    //   tcWindow.tC.event.healthKeeper(window, {
    //     AXAID: userId,
    //   });
    // }
  } catch (e) {
    handleGaError(e);
  }
};

/** KPI 17 - Number of users who click on the Show Me button in the overlay */
export const trackNavigationOverlayClicked = (
  btnText: string,
  journeyName: string,
) => {
  try {
    const tcWindow: any = window;
    const token = select(getToken);
    const userId = getValidToken(token);

    tcWindow.dataLayer.push({
      event: 'betterMe',
      icon: journeyName,
      overlayButton: btnText,
      userId: userId,
    });

    // HKEMMA-100917 - GA3 Decommission - Remove GA3
    // if (!isApp()) {
    //   tcWindow.tC.event.betterMe(window, {
    //     icon: journeyName,
    //     overlayButton: btnText,
    //     AXAID: userId,
    //   });
    // }
  } catch (e) {
    handleGaError(e);
  }
};

/**
 * GA KPI #18 - Number of users who click on the Show Me button in the overlay
 */
export const trackNavigationOverlayDisplay = (journeyName: string) => {
  try {
    if (journeyName) {
      const tcWindow: any = window;
      const token = select(getToken);
      const userId = getValidToken(token);

      tcWindow.dataLayer.push({
        event: 'screenView',
        page: `/emma-eb/${journeyName}`,
        userId: userId,
      });

      // HKEMMA-100917 - GA3 Decommission - Remove GA3
      // if (!isApp()) {
      //   tcWindow.tC.event.virtualPageview(window, {
      //     page: `/emma-eb/${journeyName}`,
      //     AXAID: userId,
      //   });
      // }
    }
  } catch (e) {
    // Means GA script is loaded but got error when trigger event.
    const tcWindow: any = window;
    if (tcWindow.tC) {
      console.error(e);
    }
  }
};

/**
 * KPI #0 default page view
 * It must use the exact URL instead of screen name
 */
export const trackDefaultPageView = (currentUrl = '') => {
  try {
    const tcWindow: any = window;
    const token = select(getToken);
    const userId = getValidToken(token);
    const changeCurrentUrl = currentUrl
      .replace(/(\/)$/, '')
      .replace('/mob', '');
    if (userId) {
      const { dataLayer } = tcWindow;
      dataLayer.push({
        event: 'screenView',
        page: maskPN(`SPA:${changeCurrentUrl}`),
      });

      // HKEMMA-100917 - GA3 Decommission - Remove GA3
      // if (!isApp()) {
      //   const { tC } = tcWindow;
      //   tC.event.virtualPageview(tcWindow, {
      //     page: changeCurrentUrl,
      //     AXAID: userId,
      //   });
      // }
    }
  } catch (error) {
    handleGaError(error);
  }
};

/** KPI 19 - Number of navigation tab clicks */
export const trackNavigationTab = (icon: string, screenName: string) => {
  try {
    const tcWindow: any = window;
    const token = select(getToken);
    const userId = getValidToken(token);

    tcWindow.dataLayer.push({
      event: 'navigationIcon',
      icon,
      screenName,
      userId: userId,
    });

    // HKEMMA-100917 - GA3 Decommission - Remove GA3
    // if (!isApp()) {
    //   tcWindow.tC.event.navigationIcon(window, {
    //     icon,
    //     screenName,
    //     AXAID: userId,
    //   });
    // }
  } catch (e) {
    handleGaError(e);
  }
};

/** KPI 45 - Number of users click on the Close button after calling the service provider for appointment */
export const trackCloseButtonClick = (
  serviceName: string,
  serviceId: string,
  displayedButtonText: string,
) => {
  try {
    const tcWindow: any = window;
    const token = select(getToken);
    const userId = getValidToken(token);

    tcWindow.dataLayer.push({
      event: 'clickClose',
      serviceName: serviceName,
      serviceId: serviceId,
      displayedButtonText: displayedButtonText,
      screenName: getGaPagePath(),
      userId: userId,
    });

    // HKEMMA-100917 - GA3 Decommission - Remove GA3
    // if (!isApp()) {
    //   tcWindow.tC.event.clickClose(tcWindow, {
    //     serviceName: serviceName,
    //     serviceId: serviceId,
    //     displayedButtonText: displayedButtonText,
    //     screenName: getGaPagePath(),
    //     AXAID: userId,
    //   });
    // }
  } catch (e) {
    handleGaError(e);
  }
};

/**
 * Send experiment ids to GA3 and GA4
 * Format: <experimentId>:<variantId>,<experimentId>:<variantId>,...
 * For example: ABC:0,DEF:1,HIJ:0
 * @param {*} experimentIds
 */
export const setupExperimentIdsToDataLayer = (experimentIds: string) => {
  try {
    // App GA3, Web GA4
    const tcWindow: any = window;
    tcWindow.dataLayer.push({
      experimentIds,
    });

    // TODO - GA3
  // HKEMMA-100917 - GA3 Decommission - Remove TC Scripts
    // if (!isApp()) {
    // tcWindow.tc_vars.experimentIds = experimentIds;
    // }
  } catch (e) {
    console.error(e);
  }
};

/** KPI 70 - Number of times the error message is shown */

export const trackDisplayErrorSuccess = (
  errorMessage: string,
  isAddErrorMessageInScreenName = true,
) => {
  try {
    const tcWindow: any = window;
    const event = 'errorMessage';
    const screenName = isAddErrorMessageInScreenName
      ? `${getGaPagePath()}#${errorMessage}`
      : getGaPagePath();
    const token = select(getToken);
    const userId = getValidToken(token);

    // HKEMMA-100917 - GA3 Decommission - Remove GA3
    // GA3 web
    // if (!isApp()) {
    //   tcWindow.tC.event.errorMessage(window, {
    //     errorMessage: errorMessage,
    //     screenName,
    //     AXAID: userId,
    //   });
    // }

    // GA3 app GA4 web
    tcWindow.dataLayer.push({
      event,
      errorMessage,
      screenName,
      userId,
    });

    // GA4 app
    trackDisplayError(screenName, errorMessage);
  } catch (e) {
    handleGaError(e);
  }
};

// custom cta button for KPI #62
export const gaClickAbleElement = (
  displayedButtonText: string,
  message: string,
) => {
  gaClickableElementCallback(
    'clickCTAButton',
    displayedButtonText,
    `${getGaPagePath()}#${message}`,
  );
};

/** KPI 55 - Number of users click / view the various types of promotion banners and pop-ups in the Home / Policy Detail / Benefit Coverage screens */

export const trackBannerOnDisplayWeb = async (
  product: string,
  planName: string,
  bannerTitle: string,
  bannerAction?: string,
) => {
  try {
    const tcWindow: any = window;
    const screenName = await getScreenName();
    const token = select(getToken);
    const userId = getValidToken(token);

    // Web GA4
    tcWindow.dataLayer.push({
      event: 'clickBanner',
      product: product,
      bannerAction: bannerAction ? bannerAction : 'click',
      bannerPlanName: planName,
      bannerTitle: bannerTitle,
      screenName: screenName,
      userId: userId,
    });

    // APP GA4
    trackBannerAction(
      product,
      bannerAction ? bannerAction : 'click',
      planName,
      bannerTitle,
      screenName,
      'clickBanner',
    );
  } catch (e) {
    handleGaError(e);
  }
};
