import { Gender, GenderKey } from '../types/customerProfileTypes';
import { EntityCode } from '../types/entityCodeTypes';

import iconStep from '../assets/images/aktivo-individual-challenge/filter/icon-step.svg';
import iconExercise from '../assets/images/aktivo-individual-challenge/filter/icon-exercise.svg';
import iconLightActivity from '../assets/images/aktivo-individual-challenge/filter/icon-light-activity.svg';
import iconSedentary from '../assets/images/aktivo-individual-challenge/filter/icon-sedentary.svg';
import iconSleep from '../assets/images/aktivo-individual-challenge/filter/icon-sleep.svg';
// page icon
import iconPageStep from '../assets/images/aktivo-individual-challenge/step.svg';
import iconPageSleep from '../assets/images/aktivo-individual-challenge/sleep.svg';
import iconPageExercise from '../assets/images/aktivo-individual-challenge/exercise.svg';
import iconPageSedentary from '../assets/images/aktivo-individual-challenge/graphic-sedentary.svg';
import iconPageLightActivity from '../assets/images/aktivo-individual-challenge/graphic-light-activity.svg';
import iconPageStepProcess from '../assets/images/aktivo-individual-challenge/annual-step-process.svg';

// MARKETPLACE
export const EMMA_MY_PRIVILEGES = 'emma-my-privileges';
export const EMMA_MY_PRIVILEGES_OVERLAY = 'emma-my-privileges-overlay-revamp';
export const EMMA_MY_PRIVILEGES_MODULE_NAME = 'myPrivileges';
export const EMMA_MY_PRIVILEGES_PATH_NAME = 'marketplace/myprivileges';
// popup module name
export const EMMA_BETTER_ME = 'emma-better-me';
export const EMMA_NOTICES = 'emma-notices';
export const EMMA_AXA_GOAL = 'emma-axa-goal';
export const EMMA_INTRO = 'emma-intro';
export const EMMA_EB_FLEXI = 'emma-eb-flexi';

// woman product start
// web
export const FERTILITY_SOLUTION = 'fertilitysolution';
export const COSMETIC_DERMATOLOGY = 'cosmeticdermatology';
export const NEURUMMEDICATION = 'neurummedication';
export const WELLNESSOFFERS = 'wellnessoffers';
export const TELECONSULTATION = 'teleconsultation';

// app
export const EMMA_PS_FERTILITY_SOLUTION = 'emma-ps-fertility-solution';
export const EMMA_PS_COSMETIC_DERMATOLOGY = 'emma-ps-cosmetic-dermatology';
export const EMMA_PS_NEURUMMEDICATION = 'emma-ps-meditation';
export const EMMA_PS_WELLNESSOFFERS = 'emma-ps-wellness-programme';
export const EMMA_PS_TELECONSULTATION = 'emma-ps-teleconsultation';
// woman product end

export const WINDOW_SIZE = {
  SMALL: 768,
  MEDIUM: 992,
  LARGE: 1200,
};

// General Feature Id
export const GeneralFeatureIds = ['vaccination', 'sportspaoffer'];

//otp time
export const TIMECOUNT = 60;

//eb-flexi-from
export const EB_FELXI_FROM = 'eb';
export const EB_FLEXI_DISCLAIMER_ID = 'EBFlexi';
export const EB_FELXI_REDIRECT = 'eb-flexi/redirect';

export const ENTITY_CODE: {
  [key in EntityCode]: EntityCode;
} = {
  LHK: 'LHK', // Life & HK
  LMA: 'LMA', // Life & Macau
  EHK: 'EHK', // Employee Benefit
  GHK: 'GHK', // Property & casualty
  G4HK: 'G4HK', // First Care
};

export const CUSTOMER_GENDER: {
  [key in GenderKey]: Gender;
} = {
  MALE: 'M',
  FEMALE: 'F',
  UNKNOWN: 'UNKNOWN',
};

export const AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_STEPS = 'steps';
export const AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_SLEEPTIME = 'sleepTime';
export const AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_EXERCISETIME = 'exerciseTime';
export const AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_LIGHTACTIVITYTIME =
  'lightActivityTime';
export const AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_SEDENTARYTIME =
  'sedentaryTime';
export const AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_AKTIVOSCORE = 'aktivoScore';
export const AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_ACTIVETIME = 'activeTime';

// page icon
export const iconPageStepPath = iconPageStep;
export const iconPageSleepPath = iconPageSleep;
export const iconPageExercisePath = iconPageExercise;
export const iconPageSedentaryPath = iconPageSedentary;
export const iconPageLightActivityPath = iconPageLightActivity;
export const iconPageStepProcessPath = iconPageStepProcess;

// filter icon
export const iconStepPath = iconStep;
export const iconSleepPath = iconSleep;
export const iconExercisePath = iconExercise;
export const iconLightActivityPath = iconLightActivity;
export const iconSedentaryPath = iconSedentary;

export const AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY = [
  {
    title: {
      en: 'Step',
      zh: '步行',
    },
    value: 1,
    iconPath: iconStepPath,
    type: AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_STEPS,
  },
  {
    title: {
      en: 'Sleep',
      zh: '睡眠',
    },
    value: 2,
    iconPath: iconSleepPath,
    type: AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_SLEEPTIME,
  },
  {
    title: {
      en: 'Exercise',
      zh: '運動',
    },
    value: 3,
    iconPath: iconExercisePath,
    type: AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_EXERCISETIME,
  },
  {
    title: {
      en: 'Light Activity',
      zh: '輕度活動',
    },
    value: 4,
    iconPath: iconLightActivityPath,
    type: AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_LIGHTACTIVITYTIME,
  },
  {
    title: {
      en: 'Sedentary',
      zh: '靜態時間',
    },
    value: 5,
    iconPath: iconSedentaryPath,
    type: AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_SEDENTARYTIME,
  },
];

export const AKTIVO_INDIVIDUAL_CHALLENGE_TYPE_PROGRESS = 'progress';
export const AKTIVO_INDIVIDUAL_CHALLENGE_TYPE_STREAK = 'streak';
export const AKTIVO_INDIVIDUAL_CHALLENGE_TYPE_MILESTONE = 'milestone';
export const AKTIVO_INDIVIDUAL_CHALLENGE_TYPE_GOAL = 'goal';

export const AKTIVO_INDIVIDUAL_CHALLENGE_TYPE = [
  {
    title: {
      en: 'Progress',
      zh: '進度',
    },
    type: AKTIVO_INDIVIDUAL_CHALLENGE_TYPE_PROGRESS,
    value: 1,
    detailType: 'ProgressBar',
  },
  {
    title: {
      en: 'Streak',
      zh: '連續',
    },
    type: AKTIVO_INDIVIDUAL_CHALLENGE_TYPE_STREAK,
    value: 2,
    detailType: 'ProgressCircle',
  },
  {
    title: {
      en: 'Milestone',
      zh: '里程碑',
    },
    type: AKTIVO_INDIVIDUAL_CHALLENGE_TYPE_MILESTONE,
    value: 3,
    detailType: 'ProgressStep',
  },
  {
    title: {
      en: 'Goal',
      zh: '目標',
    },
    type: AKTIVO_INDIVIDUAL_CHALLENGE_TYPE_GOAL,
    value: 2,
    detailType: 'ProgressCircle',
  },
];

export const AKTIVO_INDIVIDUAL_CHALLENGE_TYPE_STREAK_WEEK_LIST = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
];

export const AKTIVO_INDIVIDUAL_CHALLENGE_TYPE_STREAK_WEEK_LIST_TIPS = [
  {
    tip: {
      en: 'Coming soon',
      zh: '即將開始',
    },
  },
  {
    tip: {
      en: 'Ongoing',
      zh: '進行中',
    },
  },
  {
    tip: {
      en: 'Completed',
      zh: '完成',
    },
  },
  {
    tip: {
      en: 'Incomplete',
      zh: '未完成',
    },
  },
];

export const AKTIVO_INDIVIDUAL_HEALTH_INSIGHTS = [
  // Exercise Time
  {
    ONE: [
      {
        title: {
          en: '30 minutes of daily exercise helps keeping you fit!',
          zh: '每日運動 30 分鐘可助您保持身心健康！',
        },
      },
      {
        title: {
          en: 'Sparing half an hour each day for exercises helps you go a long way!',
          zh: '每日抽空半小時來運動對您大有脾益！',
        },
      },
      {
        title: {
          en: 'Exercise can be any activity such as jogging, swimming or biking, exercise 30 minutes a day for better health!',
          zh: '運動可以很多元化，跑步、游泳或踏單車皆可，每日運動 30 分鐘讓您更健康！',
        },
      },
    ],
    TWO: [
      {
        title: {
          en: 'Keep it up! Aim to exercise for 30 minutes daily for better health!',
          zh: '繼續努力！向每日運動 30 分鐘的目標出發，提升健康！',
        },
      },
      {
        title: {
          en: 'Solid effort! Keep up the good work and aim to get 30 minutes of daily exercise!',
          zh: '加油！不要放棄，向每日 30 分鐘運動的目標進發！',
        },
      },
      {
        title: {
          en: 'Nice work! Half and hour of daily exercise improves your long term health!',
          zh: '加把勁！每日運動半小時可改善您的長期健康！',
        },
      },
    ],
    THREE: [
      {
        title: {
          en: 'Excellent! Exercising regularly benefits your health in so many ways!',
          zh: '做得好！恆常運動對您各方面的健康都大有好處！',
        },
      },
      {
        title: {
          en: 'Brilliant! Working out regularly is one of the best gifts you can give to yourself!',
          zh: '非常好！保持運動習慣是對您可以送給自己其中一樣最好的禮物！',
        },
      },
      {
        title: {
          en: 'Fantastic! You have achieved the daily recommended goal of exercising for at least 30 mimnutes!',
          zh: '十分好！您已成功完成每日運動 30 分鐘的目標建議！',
        },
      },
    ],
  },
  // Sedentary Time
  {
    ONE: [
      {
        title: {
          en: 'Excellent! Keeping your daily sedentary time to within 8 hours contributes to your healthier lifestyle!',
          zh: '做得好！維持每日靜態時間少於 8 小時讓您的生活方式更健康！',
        },
      },
      {
        title: {
          en: 'Brilliant! Sitting less than 8 hours a day is beneficial to your health!',
          zh: '非常好！每日靜態時間少於 8 小時對您的健康有好處！',
        },
      },
      {
        title: {
          en: 'Fantastic! You have achieved the daily recommended goal of sitting less than 8 hours!',
          zh: '十分好！您已成功達成每日靜態時間少於 8 小時的目標建議！',
        },
      },
    ],
    TWO: [
      {
        title: {
          en: 'Exceeding 8 hours of daily sedentary time may impact your long term health!',
          zh: '靜態時間超過每日 8 小時可能會對您的長期健康有影響！',
        },
      },
      {
        title: {
          en: 'Avoid sitting for too long, try replacing some of your sedentary time with light activities to improve your physical lifestyle!',
          zh: '避免長時間久坐，嘗試以輕度活動代替部份靜態時間以改善您的生活方式！',
        },
      },
      {
        title: {
          en: 'Reduce your daily sedentary time to within 8 hours to improve your health!',
          zh: '減少您的每日靜態時間至 8 小時內以提升健康！',
        },
      },
    ],
  },
  // Sleep Time
  {
    ONE: [
      {
        title: {
          en: 'Sleeping between 7 - 9 hours daily is important for your health!',
          zh: '每日睡眠 7 - 9 小時對您的健康十分重要！',
        },
      },
      {
        title: {
          en: 'Getting adequate sleep of 7 - 9 hours daily is beneficial to your heart, metabolism and productivity!',
          zh: '每日 7 - 9 小時的充足睡眠對您的心臟健康、新陳代謝及工作效率都有益處！',
        },
      },
      {
        title: {
          en: 'Aim to sleep between 7 - 9 hours daily for good health!',
          zh: '以每日睡眠 7 - 9 小時為目標，為您的健康打打氣！',
        },
      },
    ],
    TWO: [
      {
        title: {
          en: 'Excellent! Getting adequate sleep plays a vital role in maintaining your health!',
          zh: '做得好！充足的睡眠對於維持健康至關重要！',
        },
      },
      {
        title: {
          en: 'Brilliant! Your sleep time is ideal for long term good health!',
          zh: '非常好！您的睡眠時間對於長遠健康十分理想！',
        },
      },
      {
        title: {
          en: 'Fantastic! Great job in achieving the daily recommended goal of sleeping between 7 - 9 hours!',
          zh: '十分好！您已成功達成每日睡眠 7 - 9 小時的目標建議！',
        },
      },
    ],
    THREE: [
      {
        title: {
          en: 'Your sleep time indicates oversleeping which may have a negative impact on your health!',
          zh: '數據顯示你的睡眠時間過長，可能會對健康有負面影響！',
        },
      },
      {
        title: {
          en: 'Oversleeping is as bad as not getting enough sleep, try to keep your sleeping time between the recommended amount of 7 - 9 hours daily!Aim to sleep the recommended amount of 7 to 9 hours daily to avoid oversleeping.',
          zh: '睡眠過度跟睡眠不足同樣對健康有害，嘗試維持建議的每日睡眠 7 - 9 小時！',
        },
      },
      {
        title: {
          en: 'Exceeding the recommended 7 - 9 hours of daily sleep may increase your risk of chronic disease!',
          zh: '睡眠時間超過建議的每日 7 - 9 小時可能會提高您患長期病患的風險！',
        },
      },
    ],
  },
  // Step
  {
    ONE: [
      {
        title: {
          en: 'Insufficient exercise may negatively impact your health. Aim to walk 7000 steps a day.',
          zh: '活動不足可能會對健康有負面影響，每日建議步行最小7000步改善身體健康。',
        },
      },
    ],
    TWO: [
      {
        title: {
          en: "Fantastic! You've achieved the recommend 7000 daily steps. Keep it up!",
          zh: '十分好！您已成功達成每日步行7000步的目標建議！',
        },
      },
    ],
  },
  // Light Activity
  {
    ONE: [
      {
        title: {
          en: 'Excessive sedentary time may negatively impact your health. ',
          zh: '數據顯示你的輕度活動時間不足，可能會對健康有負面影響！',
        },
      },
    ],
    TWO: [
      {
        title: {
          en: 'Great work! Replacing sedentary time with light activity is ideal for long term good health!',
          zh: '做得好！適量輕度活動對於維持健康至關重要！',
        },
      },
    ],
  },
];
export const AKTIVO_INDIVIDUAL_HEALTH_INSIGHTS_INDEX = ['ONE', 'TWO', 'THREE'];
export const AKTIVO_INDIVIDUAL_HEALTH_INSIGHTS_TYPE_STEPSSTATS = 'stepsStats';
export const AKTIVO_INDIVIDUAL_HEALTH_INSIGHTS_TYPE_SCORESTATS = 'scoreStats';
export const AKTIVO_INDIVIDUAL_HEALTH_INSIGHTS_TYPE_SLEEPSTATS = 'sleepStats';

// scoreStats
export const AKTIVO_INDIVIDUAL_HEALTH_INSIGHTS_TYPE_SCORESTATS_EXERCISE_TIME =
  'ExerciseTime';
export const AKTIVO_INDIVIDUAL_HEALTH_INSIGHTS_TYPE_SCORESTATS_SEDENTARY_TIME =
  'SedentaryTime';
export const AKTIVO_INDIVIDUAL_HEALTH_INSIGHTS_TYPE_SCORESTATS_LIGHT_ACTIVITY =
  'LightActivity';

export const AKTIVO_INDIVIDUAL_HEALTH_INSIGHTS_TYPE_SCORESTATS_TYPE = [
  AKTIVO_INDIVIDUAL_HEALTH_INSIGHTS_TYPE_SCORESTATS_EXERCISE_TIME,
  AKTIVO_INDIVIDUAL_HEALTH_INSIGHTS_TYPE_SCORESTATS_SEDENTARY_TIME,
  AKTIVO_INDIVIDUAL_HEALTH_INSIGHTS_TYPE_SCORESTATS_LIGHT_ACTIVITY,
];

export const AKTIVO_INDIVIDUAL_HEALTH_INSIGHTS_TYPE = [
  AKTIVO_INDIVIDUAL_HEALTH_INSIGHTS_TYPE_STEPSSTATS,
  AKTIVO_INDIVIDUAL_HEALTH_INSIGHTS_TYPE_SCORESTATS,
  AKTIVO_INDIVIDUAL_HEALTH_INSIGHTS_TYPE_SLEEPSTATS,
];

export const AKTIVO_INDIVIDUAL_HEALTH_INSIGHTS_UNIT = [
  // step
  {
    title: {
      en: 'steps',
      zh: '步',
    },
  },
  // hour
  {
    title: {
      en: 'hrs',
      zh: '小時',
    },
  },
  // mins
  {
    title: {
      en: 'mins',
      zh: '分鐘',
    },
  },
];

// delete account
// default error code
export const DELETE_ACCOUNT_DEFAULT_ERROR_CODE = 'ACCDEL01';

export const DELETE_ACCOUNT_ERROR_CODE_LIST = ['ACCDEL01', 'ACCDEL02'];

export const CALENDAR_MONTH_UNIT = [
  // JAN
  {
    title: {
      en: 'JAN',
      zh: '一月',
    },
  },
  // FEB
  {
    title: {
      en: 'FEB',
      zh: '二月',
    },
  },
  // MAR
  {
    title: {
      en: 'MAR',
      zh: '三月',
    },
  },
  // APR
  {
    title: {
      en: 'APR',
      zh: '四月',
    },
  },
  // MAY
  {
    title: {
      en: 'MAY',
      zh: '五月',
    },
  },
  // JUN
  {
    title: {
      en: 'JUN',
      zh: '六月',
    },
  },
  // JUL
  {
    title: {
      en: 'JUL',
      zh: '七月',
    },
  },
  // AUG
  {
    title: {
      en: 'AUG',
      zh: '八月',
    },
  },
  // SEP
  {
    title: {
      en: 'SEP',
      zh: '九月',
    },
  },
  // OCT
  {
    title: {
      en: 'OCT',
      zh: '十月',
    },
  },
  // NOV
  {
    title: {
      en: 'NOV',
      zh: '十一月',
    },
  },
  // DEC
  {
    title: {
      en: 'DEC',
      zh: '十二月',
    },
  },
];

export const CALENDAR_MONTH_WEEK_UNIT = [
  // SU
  {
    title: {
      en: 'SU',
      zh: '日',
    },
  },
  // MO
  {
    title: {
      en: 'MO',
      zh: '一',
    },
  },
  // TU
  {
    title: {
      en: 'TU',
      zh: '二',
    },
  },
  // WE
  {
    title: {
      en: 'WE',
      zh: '三',
    },
  },
  // TH
  {
    title: {
      en: 'TH',
      zh: '四',
    },
  },
  // FR
  {
    title: {
      en: 'FR',
      zh: '五',
    },
  },
  // SA
  {
    title: {
      en: 'SA',
      zh: '六',
    },
  },
];

export const HK_AREA_DISTRICT_MAPPING = {
  'HONG KONG': [
    'Kennedy Town',
    'Shek Tong Tsui',
    'Sai Ying Pun',
    'Sheung Wan',
    'Central',
    'Admiralty',
    'Mid-levels',
    'Peak',
    'Wan Chai',
    'Causeway Bay',
    'Happy Valley',
    'Tai Hang',
    'So Kon Po',
    "Jardine's Lookout",
    'Tin Hau',
    'Braemar Hill',
    'North Point',
    'Quarry Bay',
    'Sai Wan Ho',
    'Shau Kei Wan',
    'Chai Wan',
    'Siu Sai Wan',
    'Pok Fu Lam',
    'Aberdeen',
    'Ap Lei Chau',
    'Wong Chuk Hang',
    'Shouson Hill',
    'Repulse Bay',
    'Chung Hom Kok',
    'Stanley',
    'Tai Tam',
    'Shek O',
  ],
  KOWLOON: [
    'Tsim Sha Tsui',
    'Yau Ma Tei',
    "King's Park",
    'Mong Kok',
    'Tai Kok Tsui',
    'Mei Foo',
    'Lai Chi Kok',
    'Cheung Sha Wan',
    'Sham Shui Po',
    'Shek Kip Mei',
    'Yau Yat Tsuen',
    'Tai Wo Ping',
    'Hung Hom',
    'To Kwa Wan',
    'Ma Tau Kok',
    'Ma Tau Wai',
    'Kai Tak',
    'Kowloon City',
    'Ho Man Tin',
    'Kowloon Tong',
    'Beacon Hill',
    'San Po Kong',
    'Wong Tai Sin',
    'Tung Tau',
    'Wang Tau Hom',
    'Lok Fu',
    'Diamond Hill',
    'Tsz Wan Shan',
    'Ngau Chi Wan',
    'Ping Shek',
    'Kowloon Bay',
    'Ngau Tau Kok',
    'Jordan Valley',
    'Kwun Tong',
    'Sau Mau Ping',
    'Lam Tin',
    'Yau Tong',
    'Lei Yue Mun',
  ],
  'NEW TERRITORIES': [
    'Kwai Chung',
    'Tsing Yi',
    'Tsuen Wan',
    'Lei Muk Shue',
    'Ting Kau',
    'Sham Tseng',
    'Tsing Lung Tau',
    'Ma Wan',
    'Sunny Bay',
    'Tai Lam Chung',
    'So Kwun Wat',
    'Tuen Mun',
    'Lam Tei',
    'Hung Shui Kiu',
    'Ha Tsuen',
    'Lau Fau Shan',
    'Tin Shui Wai',
    'Yuen Long',
    'San Tin',
    'Lok Ma Chau',
    'Kam Tin',
    'Shek Kong',
    'Pat Heung',
    'Fanling',
    'Luen Wo Hui',
    'Sheung Shui',
    'Shek Wu Hui',
    'Sha Tau Kok',
    'Luk Keng',
    'Wu Kau Tang',
    'Tai Po Market',
    'Tai Po',
    'Tai Po Kau',
    'Tai Mei Tuk',
    'Shuen Wan',
    'Cheung Muk Tau',
    'Kei Ling Ha',
    'Tai Wai',
    'Sha Tin',
    'Fo Tan',
    'Ma Liu Shui',
    'Wu Kai Sha',
    'Ma On Shan',
    'Clear Water Bay',
    'Sai Kung',
    'Tai Mong Tsai',
    'Tseung Kwan O',
    'Hang Hau',
    'Tiu Keng Leng',
    'Ma Yau Tong',
    'Cheung Chau',
    'Peng Chau',
    'Lantau Island',
    'Lamma Island',
  ],
};

export const HK_ADDRESS_DISTRICT = [
  {
    optionTitle: 'Central and Western',
    optionVal: 'Central and Western',
  },
  {
    optionTitle: 'Wan Chai',
    optionVal: 'Wan Chai',
  },
  {
    optionTitle: 'Eastern',
    optionVal: 'Eastern',
  },
  {
    optionTitle: 'Southern',
    optionVal: 'Southern',
  },
  {
    optionTitle: 'Yau Tsim Mong',
    optionVal: 'Yau Tsim Mong',
  },
  {
    optionTitle: 'Sham Shui Po',
    optionVal: 'Sham Shui Po',
  },
  {
    optionTitle: 'Kowloon City',
    optionVal: 'Kowloon City',
  },
  {
    optionTitle: 'Wong Tai Sin',
    optionVal: 'Wong Tai Sin',
  },
  {
    optionTitle: 'Kwun Tong',
    optionVal: 'Kwun Tong',
  },
  {
    optionTitle: 'Kwai Tsing',
    optionVal: 'Kwai Tsing',
  },
  {
    optionTitle: 'Tsuen Wan',
    optionVal: 'Tsuen Wan',
  },
  {
    optionTitle: 'Tuen Mun',
    optionVal: 'Tuen Mun',
  },
  {
    optionTitle: 'Yuen Long',
    optionVal: 'Yuen Long',
  },
  {
    optionTitle: 'North',
    optionVal: 'North',
  },
  {
    optionTitle: 'Tai Po',
    optionVal: 'Tai Po',
  },
  {
    optionTitle: 'Sha Tin',
    optionVal: 'Sha Tin',
  },
  {
    optionTitle: 'Sai Kung',
    optionVal: 'Sai Kung',
  },
  {
    optionTitle: 'Islands',
    optionVal: 'Islands',
  },
];

export const ADDRESS_ABBREVIATION_MAPPING = [
  {
    Type: 'Flat',
    Abbreviation: 'Flt',
  },
  {
    Type: 'Room',
    Abbreviation: 'Rm',
  },
  {
    Type: 'Floor',
    Abbreviation: '/F',
  },
  {
    Type: 'Block',
    Abbreviation: 'Blk',
  },
  {
    Type: 'Court',
    Abbreviation: 'Crt',
  },
  {
    Type: 'Garden',
    Abbreviation: 'Gdn',
  },
  {
    Type: 'Building',
    Abbreviation: 'Bldg',
  },
  {
    Type: 'House',
    Abbreviation: 'Hse',
  },
  {
    Type: 'Estate',
    Abbreviation: 'Est',
  },
  {
    Type: 'Centre',
    Abbreviation: 'Ctr',
  },
  {
    Type: 'Commercial Building',
    Abbreviation: 'Comm Bldg',
  },
  {
    Type: 'Industrial Building',
    Abbreviation: 'Ind’l Bldg',
  },
  {
    Type: 'Police Married Quarter',
    Abbreviation: 'PMQ',
  },
  {
    Type: 'Fire Services Married Quarters',
    Abbreviation: 'FSMQ',
  },
  {
    Type: 'Mansion',
    Abbreviation: 'Mans',
  },
  {
    Type: 'Police Married Quarter',
    Abbreviation: 'PMQ',
  },
  {
    Type: 'Road',
    Abbreviation: 'Rd',
  },
  {
    Type: 'Street',
    Abbreviation: 'St',
  },
  {
    Type: 'Terrace',
    Abbreviation: 'Terr',
  },
  {
    Type: 'Chinese University of Hong Kong',
    Abbreviation: 'CUHK',
  },
  {
    Type: 'The Hong Kong University of Science & Technology',
    Abbreviation: 'HKUST',
  },
  {
    Type: 'The Polytechnic University',
    Abbreviation: 'PolyU',
  },
  {
    Type: 'City University of Hong Kong',
    Abbreviation: 'CityU',
  },
  {
    Type: 'East',
    Abbreviation: 'E',
  },
  {
    Type: 'South',
    Abbreviation: 'S',
  },
  {
    Type: 'West',
    Abbreviation: 'W',
  },
  {
    Type: 'North',
    Abbreviation: 'N',
  },
  {
    Type: 'New Territories',
    Abbreviation: 'NT',
  },
  {
    Type: 'Kowloon',
    Abbreviation: 'KLN',
  },
  {
    Type: 'Hong Kong',
    Abbreviation: 'H.K.',
  },
];
