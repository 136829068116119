import React, { Component, ComponentClass } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss';
import '../../common/scss/modal.scss';

import Cookies from 'cookies-js';
import routeRegistry from '../../registries/RouteRegistry';
import { Route, Link, Switch } from 'react-router-dom';

import {
  getToken,
  getPostLoginRedirectUri,
  getTokenExpireIn,
  getTokenFetchTime,
  getIsForceLogout,
  getIsShowReloginPopup,
  getAktivoMemberId,
  getAktivoAuth,
  getAktivoDeviceStatus,
} from '../../selectors/authSelectors';
import { getModalVisible } from '../../selectors/uiSelectors';
import { getIsShowMembershipTutorial } from '../../modules/axagoal/selectors/vhisSelectors';
import { connect } from 'react-redux';
import browserHistory from '../../stores/browserHistory';
import {
  setPostRedirectUri,
  refreshTokenSuccess,
  signOut,
  hideReloginPopup,
} from '../../actions/authActions';
import { refreshSessionToken } from '../../actions/sessionAction';

import Header from '../../components/Header';
import MembershipYearDefinitionTutorial from '../../components/MembershipYearDefinitionTutorial';
import {
  extractRelativeUrl,
  getGreetingMessage,
  isApp,
  getBaseUrl,
  resolvePublicAssets,
  resolveLocalizedText,
} from '../../utils';
import SideBarItem, { SideBarItemProps } from '../../components/SideBarItem';
import ChatbotButtonContainer from '../../modules/chatbot/containers/ChatbotButtonContainer';
import { t } from '../../locales';
import {
  getCustomer,
  getCmsProfile,
  getWelcomeLaunchpadFeatures,
  getLanguage,
  getCustomerIconGender,
  getCustomerLogin,
} from '../../selectors/userSelectors';
import BackNavContainer from '../BackNavContainer';
import ChatbotWebView from '../../modules/chatbot/containers/ChatbotWebView';
import Footer from '../../components/Footer';
import { putCustState, fetchCustState } from '../../actions/userActions';
import { fetchHealthKeeperToken } from '../../modules/healthkeeper/actions/healthKeeperAction';
import { frameAppMounted } from '../../actions/frameActions';
import { AxaCustomer } from '../../types/axaTypes';
import env from '../../env';
import {
  EmmaFactory,
  TokenRefreshEventName,
  AxaAuth,
  EmmaReadyPayload,
  EmmaWebHandler,
  TopBarOption,
} from '@axa-asia/hk-emma-js';
import moment from 'moment';
import _ from 'lodash';
import { getIdleTimeoutInMinutes } from '../../selectors/configSelectors';
import {
  getApiKeyFromRedux,
  refreshToken,
} from '../../utils/myAxaFetchWithToken';
import {
  goto,
  hideBackButton,
  showBackButton,
} from '../../actions/historyActions';
import { UnregisterCallback } from 'history';
import {
  getHaveReadNewFeatures,
  getMyPrivilegesOverlay,
  getBetterMeRedDotMaxCount,
  getMyPrivilegesRedDotMaxCount,
  getIsShowRedDot,
} from '../../selectors/sideBarItemSelectors';
import {
  pushHaveReadNewFeature,
  sidebarItemMounted,
  pushHaveReadNewFeatureSingle,
} from '../../actions/sideBarItemActions';
import MyPrivilegesOverlay from '../../components/MyPrivilegesOverlay/index';
import PromotionLaunchpadContainer, {
  LaunchpadStepProps,
} from '../../components/WelcomeLaunchpadOverlay';
import {
  EMMA_MY_PRIVILEGES,
  EMMA_MY_PRIVILEGES_MODULE_NAME,
  EMMA_BETTER_ME,
  EMMA_MY_PRIVILEGES_OVERLAY,
  EMMA_INTRO,
  CUSTOMER_GENDER,
} from '../../constants/constants';
import { myPrivilegesFeatures } from '../../modules/myPrivileges/actions/myPrivilegesAction';
import {
  getIsBetterMeShowPopup,
  getIsLaunchpadShowPopup,
} from '../../selectors/myPrivilegesOverlaySelectors';
import {
  isShowModal,
  getPayload,
} from '../../modules/emma-modal/selectors/selector';
import HomePagePopUpRegistry from '../../registries/HomePagePopUpRegistry';
import { NewFeaturepiRes } from '../../types/sideBarItemTypes';
import EmmaWelcomeSlick from '../../components/EmmaWelcomeSlick/EmmaWelcomeSlick';
import animation1 from './animations/p1.js';
import animation2 from './animations/p2.js';
import animation3 from './animations/p3.js';
import {
  getGaPagePath,
  trackProfileAvatar,
  trackProfileName,
  trackScrollDepth,
  TrackScrollDepthDirection,
} from '../../utils/generalGaEventAnalytics';
import EmmaModalContainer from '../../components/EmmaModalView/EmmaModalContainer/EmmaModalContainer';
import EmmaFullscreenModalContainer from '../../components/EmmaModalView/EmmaFullscreenModalContainer/EmmaFullscreenModalContainer';
import { objectFormat } from '../../utils/WebUtils';
import { verticalHandler } from '@axa-asia/hk-emma-web-module/lib/ga-lib/GaScrollDepthListener';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '@axa-asia/emma-stylesheet/lib/components/Dialog';
import { getCustomerProfileModalConfig } from '../../modules/emma-customer-profile/selectors/customerProfileSelectors';
import { MOB_PREFIX } from '../../actions/appActions';
import { getCorpWebIndicatorConfig } from '../../modules/emma-webview/selectors/webviewSelector';
import { getShouldShowChatbot } from '../../modules/chatbot/selectors/configSelectors';
import {
  showChatbot,
  hideChatbot,
} from '../../modules/chatbot/actions/chatbotAction';
import {
  getAllPoliciesFromStore,
  getPolicyNumberFromStore,
} from '../../selectors/policyNumberSelector';
import { AktivoAuth, AktivoDevices } from '../../types/authTypes';
import { getPreviousUrl } from '@axa-asia/hk-emma-web-module/lib/ga-lib/GaPreviousScreenUtil';
import { RHS_CONTAINER_ID } from '@axa-asia/emma-loading-widget';
import { Emma404 } from '@axa-asia/emma-stylesheet/lib/index';
import { ErrorBoundary } from '../../components/ErrorBoundary/index';
import EBFlexOverlayView from '../../components/EBFlexiBanner/EBFlexOverlayView';
import { useCustomerLogin } from '@axa-asia/utils';
import type { CustomerLogin, Policy } from '@axa-asia/utils';
import { store } from '../../stores';
import { getAllPoliciesSelector } from 'src/modules/policy-list/selectors/policyListSelectors';
import { FETCH_PROLICY_LIST } from '../../../src/modules/policy-list/actions';

const LAST_ACTIVE_TIME_KEY = `__EMMA_LAST_ACTIVE_TIME__`;

interface FrameContainerProps {
  token: string;
  tokenExpireIn: number;
  tokenFetchTime: string;
  signInUrl: string;
  lang: string;
  postLoginRedirectUri: string;
  customer?: AxaCustomer;
  cmsProfile?: string[];
  onSetPostRedirectUri: (uri: string) => void;
  onFetchHealthKeeper: () => void;
  onRefreshToken: (auth: AxaAuth) => void;
  onSignOut: () => void;
  onHideBackButton: () => void;
  onShowBackButton: () => void;
  idleTimeoutInMinutes: number;
  modalVisible: boolean;
  haveReadNewFeatures?: Array<NewFeaturepiRes>;
  onClickPartnerService: (id: string, clickCount: number) => void;
  MyPrivilegesOverlayModel: any;
  isBetterMe: boolean;
  IsShowPopup: boolean;
  isShowLaunchpadPopup: boolean;
  getIsLaunchpadShowPopup: boolean;
  redDotMaxCount: number;
  onClickSinglePartnerService: (
    id: string,
    clickCount: number,
    redDotMaxCount: number,
  ) => void;
  mpRedDotMaxCount: number;
  isShowMPRedDot: boolean;
  launchpadFeatures: {
    animations?: Array<LaunchpadStepProps>;
    slides?: Array<LaunchpadStepProps>;
    getStarted?: string;
    skip?: string;
  };
  customerIconGender: string | undefined;
  isShowFullscreenModal: boolean;
  profileModalConfig: any;
  isForceLogout: boolean;
  isShowReloginPopup: boolean;
  isShowMembershipTutorial: boolean;
  onHideReloginPopup: () => void;
  corpWebIndicator: any;
  shouldShowChatbot: boolean;
  onShowChatbot: () => void;
  onHideChatbot: () => void;
  onFetchPolicy: (featureName: string) => Promise<{ policyList: Policy[] }>;
  aktivoId: string;
  aktivoAuth: AktivoAuth;
  aktivoDeviceStatus: AktivoDevices;
  customerLogin: CustomerLogin;
}

interface FrameContainerState {
  isShowingChat: boolean;
  isShowingSideBar: boolean;
  isShowRedDot: boolean;
  isShowLaunchpad: boolean;
}

class FrameContainer extends Component<
  FrameContainerProps,
  FrameContainerState
> {
  //lastActiveTime: moment.Moment = moment();
  idleTimeoutTimer?: NodeJS.Timer;
  emmaWebHandler: EmmaWebHandler;
  unregisterBrowserHistoryListener?: UnregisterCallback;
  constructor(props: FrameContainerProps) {
    super(props);

    const { isForceLogout, isShowReloginPopup } = props;

    this.state = {
      isShowingChat: false,
      isShowingSideBar: false,
      isShowRedDot: true,
      isShowLaunchpad: true,
    };
    this.emmaWebHandler = new EmmaWebHandler();
    this.setHaveReadNewFeature = this.setHaveReadNewFeature.bind(this);
    this.onClickShadow = this.onClickShadow.bind(this);
    this.setSlidesData = this.setSlidesData.bind(this);
  }

  showSideBar = () => {
    this.setState({ isShowingSideBar: true });
  };

  commonHaveReadNewFeature = () => {
    this.setState({ isShowRedDot: false });
    const { redDotMaxCount } = this.props;
    this.props.onClickPartnerService(
      EMMA_MY_PRIVILEGES_OVERLAY,
      redDotMaxCount,
    );
  };

  hideSideBar = (moduleName?: string) => {
    this.setState({ isShowingSideBar: false });
    if (
      moduleName === EMMA_MY_PRIVILEGES_MODULE_NAME &&
      this.state.isShowRedDot
    ) {
      this.commonHaveReadNewFeature();
    }
  };

  setHaveReadNewFeature = () => {
    this.commonHaveReadNewFeature();
    HomePagePopUpRegistry.setIsShowPopupCompleted(EMMA_BETTER_ME);
    goto('emma-my-privileges-show-me');
  };

  onClickShadow = () => {
    const { redDotMaxCount } = this.props;
    this.props.onClickSinglePartnerService(
      EMMA_MY_PRIVILEGES_OVERLAY,
      1,
      redDotMaxCount,
    );
    HomePagePopUpRegistry.setIsShowPopupCompleted(EMMA_BETTER_ME);
  };

  toggleSideBar = () => {
    this.setState((prevState) => ({
      isShowingSideBar: !prevState.isShowingSideBar,
    }));
  };

  updateWindowDimensions = () => {
    this.hideSideBar();
  };

  resetIdleTimer = () => {
    localStorage.setItem(LAST_ACTIVE_TIME_KEY, moment().format());
  };

  emmaJsReady(): EmmaReadyPayload {
    const {
      token,
      tokenExpireIn,
      tokenFetchTime,
      aktivoId,
      aktivoAuth,
      aktivoDeviceStatus,
      cmsProfile,
    } = this.props;

    return {
      auth: {
        token,
        expiresIn: tokenExpireIn,
        fetchTime: tokenFetchTime,
      },
      deviceInfo: {
        platformOS: window.navigator.appVersion,
        userAgent: window.navigator.userAgent,
        timezone: `${new Date().getTimezoneOffset()}`,
      },
      env: {
        urlScheme: `${getBaseUrl()}/`,
        version: env.WEBAPP_VERSION,
        build: env.WEBAPP_VERSION,
      },
      aktivoConfig: {
        aktivoId,
        auth: aktivoAuth,
        devices: aktivoDeviceStatus,
      },
      cmsProfile: cmsProfile,
    };
  }

  componentDidMount() {
    const {
      postLoginRedirectUri,
      onSetPostRedirectUri,
      onFetchHealthKeeper,
      idleTimeoutInMinutes,
      onHideBackButton,
      onShowBackButton,
      corpWebIndicator,
    } = this.props;

    frameAppMounted();
    onShowBackButton();

    if (postLoginRedirectUri) {
      onSetPostRedirectUri(``);
      browserHistory.push(postLoginRedirectUri);
    } else {
      const relativeUrl = extractRelativeUrl();
      const segments = relativeUrl.split(`/`).filter((s) => !!s);
      if (segments.length <= 0) {
        browserHistory.replace(`/home`);
      }
      //when user use link to go to myprivileges page
      if (relativeUrl.includes('myprivileges')) {
        this.setState({ isShowRedDot: false });
        sidebarItemMounted();
      }
    }
    onFetchHealthKeeper();

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('mousemove', _.debounce(this.resetIdleTimer, 3000));
    window.addEventListener(
      'touchstart',
      _.debounce(this.resetIdleTimer, 3000),
    );

    if (isApp()) {
      EmmaFactory.createInstance().then((e) => {
        e.instance.addEventListener(TokenRefreshEventName, (auth: AxaAuth) => {
          const { onRefreshToken } = this.props;
          onRefreshToken(auth);
        });
      });
    }

    this.resetIdleTimer();
    this.idleTimeoutTimer = setInterval(() => {
      const lastActiveTime = moment(
        localStorage.getItem(LAST_ACTIVE_TIME_KEY) || undefined,
      );

      const diffInMinutes = Math.abs(lastActiveTime.diff(moment(), 'minute'));
      if (diffInMinutes >= idleTimeoutInMinutes) {
        this.idleTimeoutTimer && clearInterval(this.idleTimeoutTimer);
        this.logout();
      }
    }, 10 * 1000);

    this.unregisterBrowserHistoryListener = browserHistory.listen(() => {
      Cookies.expire('Authorization');
      Cookies.expire('authorization');
    });

    this.emmaWebHandler.serve(
      () => this.emmaJsReady(),
      () => refreshToken(),
      (url: any) => goto(url),
      () => browserHistory.push('/home'),
      (key: any, value: any) => putCustState(key, value, `emma`),
      (key: any) => fetchCustState(key, `emma`),
      () => onHideBackButton(),
      () => onShowBackButton(),
      () => Promise.resolve(''),
      corpWebIndicator.whitelistRegExp || [],
      () => {
        const policyNumber = getPolicyNumberFromStore();
        return Promise.resolve({ policyNumber: policyNumber || '' });
      },
      () => Promise.resolve(getPreviousUrl(1) || ''),
      () => {
        const customerLogin = getCustomerLogin(store.getState());
        return Promise.resolve(customerLogin);
      },
      (options) => this.handleSetTopBar(options),
      async () => getApiKeyFromRedux(),
      // TODO: add queue it here in R3
      async () => ({}),
      async ({ featureName }) => {
        const policyList = await Promise.resolve({
          policyList: getAllPoliciesFromStore(),
        });
        if (policyList && policyList.policyList.length > 0) {
          return policyList;
        }
        return this.props.onFetchPolicy(featureName);
      },
      () => (location.href = '/'),
    );
    // myPrivilegesFeatures();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    this.unregisterBrowserHistoryListener &&
      this.unregisterBrowserHistoryListener();
  }

  handleSetTopBar = (options: TopBarOption) => {
    if (options.showBackBtn === false) {
      this.props.onHideBackButton();
    }
  };

  showChat = () => {
    // this.setState({ isShowingChat: true });
    this.props.onShowChatbot();
  };
  hideChat = () => {
    // this.setState({ isShowingChat: false });
    this.props.onHideChatbot();
  };

  isClickCount(id: string, redDotMaxCount: number) {
    const { haveReadNewFeatures } = this.props;
    const NewFeaturesResult = _.find(
      haveReadNewFeatures,
      (item: { id: string }) => item.id === id,
    );
    const clickCount = NewFeaturesResult ? NewFeaturesResult['clickCount'] : 0;
    const isClickCount = clickCount < redDotMaxCount ? true : false;
    return isClickCount;
  }

  isShowBetterMe() {
    const { isBetterMe, IsShowPopup, redDotMaxCount } = this.props;
    const isClickCount = this.isClickCount(
      EMMA_MY_PRIVILEGES_OVERLAY,
      redDotMaxCount,
    );
    const isEnableBetterMe =
      isBetterMe && isClickCount && IsShowPopup ? true : false;
    return isEnableBetterMe;
  }

  isShowLaunchpad() {
    const { isShowLaunchpadPopup, launchpadFeatures } = this.props;
    const islaunchpadFeatures =
      launchpadFeatures && launchpadFeatures.slides ? true : false;
    const isClickCount = this.isClickCount(EMMA_INTRO, 1);
    const isEnableBetterMe =
      isShowLaunchpadPopup && isClickCount && islaunchpadFeatures
        ? true
        : false;
    return isEnableBetterMe;
  }

  isShowMyPrivilegesRedDot(isShowMPRedDot: boolean) {
    const { mpRedDotMaxCount } = this.props;
    const { isShowRedDot } = this.state;
    const isShowMyPrivilegesRedDot =
      isShowRedDot &&
      isShowMPRedDot &&
      this.isClickCount(EMMA_MY_PRIVILEGES, mpRedDotMaxCount);
    return isShowMyPrivilegesRedDot;
  }

  getShowCustomerProfileBg = () => {
    const currentUrl = new URL(window.location.href);
    return currentUrl.pathname.split('/').pop() === 'customerprofile';
  };

  renderContentSection() {
    const { isShowMembershipTutorial } = this.props;
    const routeMap = routeRegistry.getScreens();
    const routes = Object.keys(routeMap);

    return (
      <section className="section">
        {isShowMembershipTutorial && (
          <MembershipYearDefinitionTutorial
            showModal={isShowMembershipTutorial}
          />
        )}
        <Switch>
          {routes.map((r) => {
            const Container: ComponentClass = routeMap[r].container;
            return (
              <Route
                exact={routeMap[r].exact}
                key={r}
                path={r}
                render={(props) => <Container {...props} />}
              />
            );
          })}
          <Route
            path="*"
            render={() => (
              <div className="container404">
                <Emma404
                  lang={
                    this.props.lang === 'en' || this.props.lang === 'zh'
                      ? this.props.lang
                      : 'en'
                  }
                  gotoHome={() => goto('emma-home')}
                />
              </div>
            )}
          />
        </Switch>
      </section>
    );
  }

  getAnimation(index: number) {
    const animationsArr = [animation1, animation2, animation3];
    const arrLength = animationsArr.length;
    if (index >= arrLength) index = arrLength - 1;
    return animationsArr[index];
  }

  setSlidesData() {
    const { launchpadFeatures, lang } = this.props;
    const slidesArray: any = [];
    if (launchpadFeatures) {
      const { slides } = launchpadFeatures;
      if (slides && slides.length > 0) {
        let slidesItem = {};
        let i = 0;
        slides.forEach((element: any) => {
          slidesItem = {
            src: element.image ? resolvePublicAssets(`${element.image}`) : '',
            alt: '',
            description:
              element && element.description ? element.description[lang] : [],
            animation: this.getAnimation(i),
          };
          slidesArray.push(slidesItem);
          i++;
        });
      }
    }
    return slidesArray;
  }

  goToCustomerProfile() {
    goto('emma-customer-profile');
  }

  handleRelogin = () => {
    const { onSignOut, onHideReloginPopup } = this.props;

    onHideReloginPopup();
    onSignOut();
  };

  handleScroll = verticalHandler((ratio: number) => {
    const screenName: string = getGaPagePath();

    if (
      [
        '/policy-details/',
        '/myprivileges',
        '/check-coverage',
        '/aktivo-my-wellness',
        '/referral-programme/referral-form',
        '/programme-registration/form',
      ].some((path) => screenName.includes(path))
    ) {
      trackScrollDepth(ratio, TrackScrollDepthDirection.VERTICAL, screenName);
    }
  });

  render() {
    return isApp() ? this.renderContentSection() : this.renderFullFrame();
  }

  renderFullFrame() {
    const slides = this.setSlidesData();
    const {
      isForceLogout,
      isShowReloginPopup,
      profileModalConfig,
      customer,
      modalVisible,
      MyPrivilegesOverlayModel,
      isShowMPRedDot,
      launchpadFeatures,
      customerIconGender,
      isShowFullscreenModal,
      isShowMembershipTutorial,
    } = this.props;
    const { isShowingChat, isShowingSideBar, isShowLaunchpad } = this.state;
    const routeMap = routeRegistry.getScreens();
    const routes = Object.keys(routeMap);
    const isShowBetterMe = this.isShowBetterMe();
    const isShowRedDot = this.isShowMyPrivilegesRedDot(isShowMPRedDot);
    const launchpadProps = {
      onClickTryNow: () => this.setState({ isShowLaunchpad: false }),
    };
    const getStartedLabel = launchpadFeatures.getStarted
      ? resolveLocalizedText(launchpadFeatures.getStarted)
      : '';
    const skipValue = launchpadFeatures.skip
      ? resolveLocalizedText(launchpadFeatures.skip)
      : '';
    const isShowCustomerProfileBg = this.getShowCustomerProfileBg();

    // Sort nav item by priority field value
    const filteredSideBarItemRoute = routes
      .map((r) =>
        routeMap[r].sidebarItem ? { route: r, ...routeMap[r] } : undefined,
      )
      .filter((v) => v && v.route.indexOf(`/${MOB_PREFIX}`) === -1);
    const sortedRoute = _.orderBy(
      filteredSideBarItemRoute,
      ['sidebarItem.priority'],
      ['asc'],
    );

    return (
      <div className="App">
        <Header toggleSideBar={this.toggleSideBar} />
        <main
          className={isShowFullscreenModal ? 'fullscreen-modal-opened' : ''}
        >
          <EmmaFullscreenModalContainer />
          {this.isShowLaunchpad() && isShowLaunchpad ? (
            <div className="emma-welcome-launchpad">
              {/* <PromotionLaunchpadContainer {...launchpadProps} /> */}
              <EmmaWelcomeSlick
                {...launchpadProps}
                slides={slides}
                getStartedLabel={getStartedLabel}
                skipValue={skipValue}
              />
            </div>
          ) : (
            <aside className={`side-bar ${isShowingSideBar ? 'opened' : ''}`}>
              <div className="sidebar-item-logout">
                <span
                  className="cursor-pointer"
                  onClick={() => this.logout()}
                  data-enable-ga
                  data-ga-type="button"
                >
                  <div className="logout-btn">{t(`LOGOUT`)}</div>
                </span>
              </div>
              <div className={`customer-name content-align-level`}>
                <div
                  className="customer-img"
                  data-enable-ga
                  data-ga-type="button"
                  data-ga-text={'Profile Avatar'}
                  onClick={() => {
                    this.goToCustomerProfile();
                    trackProfileAvatar();
                  }}
                >
                  {customerIconGender === CUSTOMER_GENDER.FEMALE && (
                    <img
                      src={resolvePublicAssets(
                        `assets/images/customer-profile/female.png`,
                      ).replace('.png', '@3x.png')}
                      alt=""
                    />
                  )}
                  {customerIconGender === CUSTOMER_GENDER.MALE && (
                    <img
                      src={resolvePublicAssets(
                        `assets/images/customer-profile/male.png`,
                      ).replace('.png', '@3x.png')}
                      alt=""
                    />
                  )}
                  {customerIconGender === CUSTOMER_GENDER.UNKNOWN && (
                    <img
                      src={resolvePublicAssets(
                        `assets/images/customer-profile/unisex.png`,
                      ).replace('.png', '@3x.png')}
                      alt=""
                    />
                  )}
                  {!customerIconGender && (
                    <img
                      src={resolvePublicAssets(
                        `assets/images/customer-profile/unisex.png`,
                      ).replace('.png', '@3x.png')}
                      alt=""
                    />
                  )}
                </div>
                <div
                  className="customer-span"
                  data-enable-ga
                  data-ga-type="button"
                  data-ga-text={'Profile Name'}
                  onClick={() => {
                    this.goToCustomerProfile();
                  }}
                >
                  <span>{getGreetingMessage()}</span>
                  <span
                    onClick={() => {
                      trackProfileName();
                    }}
                  >
                    {customer
                      ? customer.englishName
                      : t('CWP_CUSTOMER_WELCOME_TEXT')}
                  </span>
                </div>
              </div>
              <ul>
                {sortedRoute.map((r) =>
                  r ? (
                    <li key={r.route}>
                      <Link
                        to={r.route}
                        onClick={() => {
                          this.hideSideBar(r.moduleName);
                        }}
                        data-enable-ga="true"
                        data-ga-type="link"
                      >
                        <SideBarItem
                          {...({
                            isShowRedDot,
                            moduleName: r.moduleName,
                            route: r.route,
                            ...r.sidebarItem,
                          } as SideBarItemProps)}
                        />
                      </Link>
                    </li>
                  ) : null,
                )}
              </ul>
              <Footer responsive={false} key={'frame-footer'} />
            </aside>
          )}
          <section
            id={RHS_CONTAINER_ID}
            className={`section ${
              isShowCustomerProfileBg ? 'customer-profile-section-bg' : ''
            }`}
          >
            <div
              className={`scroll-panel${
                !modalVisible ? ' no-modal-showing' : ''
              }`}
              onScroll={this.handleScroll}
            >
              <BackNavContainer />
              {this.renderContentSection()}
              {/* {isShowMembershipTutorial && <MembershipYearDefinitionTutorial showModal={isShowMembershipTutorial}/>} */}
              <Dialog
                show={isForceLogout && isShowReloginPopup}
                onHide={() => {}}
              >
                <DialogHeader>
                  {profileModalConfig.logoutPopupTitle
                    ? resolveLocalizedText(profileModalConfig.logoutPopupTitle)
                    : ''}
                </DialogHeader>
                <DialogBody>
                  <div>
                    {profileModalConfig.logoutPopupText
                      ? resolveLocalizedText(profileModalConfig.logoutPopupText)
                      : ''}
                  </div>
                </DialogBody>
                <DialogFooter>
                  <div className="dailog-footer-btn-align__center">
                    <button
                      data-enable-ga
                      data-ga-type="button"
                      className="emma-stylesheet-primary-default-button"
                      onClick={() => this.handleRelogin()}
                    >
                      {profileModalConfig.logoutPopupBtn
                        ? resolveLocalizedText(
                            profileModalConfig.logoutPopupBtn,
                          )
                        : ''}
                    </button>
                  </div>
                </DialogFooter>
              </Dialog>
            </div>
          </section>
        </main>
        <ErrorBoundary>
          <ChatbotButtonContainer
            isShowingChat={this.props.shouldShowChatbot}
            showChat={this.showChat}
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <ChatbotWebView
            isShowingChat={this.props.shouldShowChatbot}
            hideChat={this.hideChat}
          />
        </ErrorBoundary>
        <Footer />
        <div>
          {isShowBetterMe && (
            <MyPrivilegesOverlay
              setHaveReadNewFeature={this.setHaveReadNewFeature}
              MyPrivilegesOverlayModel={MyPrivilegesOverlayModel}
              onClickShadow={this.onClickShadow}
            ></MyPrivilegesOverlay>
          )}
          <EmmaModalContainer />
        </div>

        <EBFlexOverlayView />
      </div>
    );
  }

  logout() {
    const { onSignOut } = this.props;
    onSignOut();
  }

  static mapStateToProps(state: any, ownProps: any) {
    const haveReadNewFeature = getHaveReadNewFeatures(state);
    const myPrivilegesOverlay = getMyPrivilegesOverlay(state);
    const isBetterMe =
      JSON.stringify(myPrivilegesOverlay) !== '{}' ? true : false;
    const lang = getLanguage(state);

    const payload = getPayload(state)
      ? objectFormat(getPayload(state), lang)
      : getPayload(state);
    const query = payload ? objectFormat(getPayload(state), lang) : payload;

    const isShowFullscreenModal =
      isShowModal(state) &&
      query &&
      query.moduleName === 'emma-modal' &&
      query.screenName === 'emma-modal-fullscreen-modal';
    const isForceLogout = getIsForceLogout(state);
    const profileModalConfig = getCustomerProfileModalConfig(state);
    const isShowReloginPopup = getIsShowReloginPopup(state);
    const isShowMembershipTutorial = getIsShowMembershipTutorial(state);
    const shouldShowChatbot = getShouldShowChatbot(state);

    return {
      token: getToken(state),
      tokenExpireIn: getTokenExpireIn(state),
      tokenFetchTime: getTokenFetchTime(state),
      postLoginRedirectUri: getPostLoginRedirectUri(state),
      customer: getCustomer(state),
      cmsProfile: getCmsProfile(state),
      idleTimeoutInMinutes: getIdleTimeoutInMinutes(state),
      modalVisible: getModalVisible(state),
      haveReadNewFeatures: haveReadNewFeature
        ? haveReadNewFeature.features
        : undefined,
      MyPrivilegesOverlayModel: myPrivilegesOverlay,
      isBetterMe: isBetterMe,
      isShowLaunchpadPopup: getIsLaunchpadShowPopup(state),
      IsShowPopup: getIsBetterMeShowPopup(state),
      redDotMaxCount: getBetterMeRedDotMaxCount(state),
      mpRedDotMaxCount: getMyPrivilegesRedDotMaxCount(state),
      isShowMPRedDot: getIsShowRedDot(state),
      launchpadFeatures: getWelcomeLaunchpadFeatures(state),
      lang,
      customerIconGender: getCustomerIconGender(state),
      isShowFullscreenModal,
      profileModalConfig,
      isForceLogout,
      isShowReloginPopup,
      isShowMembershipTutorial,
      corpWebIndicator: getCorpWebIndicatorConfig(state),
      shouldShowChatbot,
      aktivoId: getAktivoMemberId(state),
      aktivoAuth: getAktivoAuth(state),
      aktivoDeviceStatus: getAktivoDeviceStatus(state),
    };
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      onSetPostRedirectUri: (uri: string) => dispatch(setPostRedirectUri(uri)),
      onFetchHealthKeeper: () => dispatch(fetchHealthKeeperToken()),
      onRefreshToken: (auth: AxaAuth) => {
        dispatch(refreshTokenSuccess(auth));
        // comment: remove A-HKG-0018-V010
        // dispatch(refreshSessionToken(auth.token));
      },
      onSignOut: () => dispatch(signOut()),
      onHideBackButton: () => dispatch(hideBackButton()),
      onShowBackButton: () => dispatch(showBackButton()),
      onClickPartnerService: (id: string, clickCount: number) => {
        dispatch(pushHaveReadNewFeature(id, clickCount));
      },
      onClickSinglePartnerService: (
        id: string,
        clickCount: number,
        redDotMaxCount: number,
      ) => {
        dispatch(pushHaveReadNewFeatureSingle(id, clickCount, redDotMaxCount));
      },
      onHideReloginPopup: () => {
        dispatch(hideReloginPopup());
      },
      onShowChatbot: () => dispatch(showChatbot()),
      onHideChatbot: () => dispatch(hideChatbot()),
      onFetchPolicy: (featureName) =>
        new Promise((resolve) => {
          dispatch({
            type: FETCH_PROLICY_LIST,
            payload: {
              featureName: featureName,
              callback: (result) => {
                resolve({
                  policyList: result.map((e) => ({
                    ...e.policy,
                    policyRiders: e.policyRiders,
                  })),
                });
              },
            },
          });
        }),
    };
  }
}

const ConnectedFrameContainer = connect(
  FrameContainer.mapStateToProps,
  FrameContainer.mapDispatchToProps,
)(FrameContainer);

export default ConnectedFrameContainer;
