// Useful for standalone packages to get data from url
// All our application used different routing mechnisms

import { match } from 'path-to-regexp';

const withPrecedingSlash = (str: string) => (/^\//.test(str) ? str : `/${str}`);

const getVariableNamesFromEmmaUrl = (emmaUrl: string) => {
  const matches = emmaUrl.match(/\$\{[^{}]+\}/g) || [];
  return matches.map((m) => m.replace(/[${}]/g, ``));
};

export const getPathRegexpFromEmmaUrl = (emmaUrl: string) => {
  const escapedUrl = emmaUrl.split('?').join('\\?');
  const variableNames = getVariableNamesFromEmmaUrl(escapedUrl);

  return withPrecedingSlash(
    variableNames
      .map((x) => '${' + x + '}')
      .reduce(
        (str, template, i) => str.replace(template, `:${variableNames[i]}`),
        escapedUrl,
      ),
  );
};

export const getDataFromUrl = (emmaUrl: string) => {
  const fn = match(getPathRegexpFromEmmaUrl(emmaUrl), {
    decode: decodeURIComponent,
    start: false,
    end: false,
  });

  const asPath = location.pathname + location.search;

  const data = fn(asPath);

  if (!data) return {};

  return data.params;
};

export const getUrlWithParams = (
  emmaUrl: string,
  params: Record<string, string>,
) => {
  const variableNames = getVariableNamesFromEmmaUrl(emmaUrl);

  return variableNames
    .map((x) => '${' + x + '}')
    .reduce(
      (str, template, i) => str.replace(template, params[variableNames[i]]),
      emmaUrl,
    );
};
