import {
  AUTO_COMPLETE_ADDRESS_CHANGED,
  UPDATE_ADDRESS_FORM_STATES,
  UPDATE_ADDRESS_GOGREEN_LIST,
  FETCH_ADDRESS_OTHER_POLICY_LIST_SUCCESS,
  FETCH_ADDRESS_OTHER_POLICY_LIST_FAIL,
  UPDATE_SELECTED_LIST,
  UPDATE_ADDRESS_SAVE_FIELD,
  SUBMIT_CHANGE_ADDRESS_FORM,
  SUBMIT_CHANGE_ADDRESS_FORM_SUCCESS,
  SUBMIT_CHANGE_ADDRESS_FORM_FAILURE,
  AUTO_COMPLETE_MAP_ADDRESS_CHANGED,
  AUTO_COMPLETE_MAP_ADDRESS_CHANGED_CONFIRM,
  SET_UP_SHOW_ADDRESS_POPUP,
  SET_UP_CONFIRM_LOADING_BUTTON,
  SET_UP_IS_HK_ADDRESS_CASE,
  UPDATELOAD_GOOGLE_MAP_JS_SCRIPT_STATUS,
  UPDATE_EDIT_PAGE_LOADING,
  AUTO_COMPLETE_ADDRESS_CHANGED_ENTER,
  UPDATE_IS_GOOGLE_MAP_JS_SCRIPT_ERROR,
} from '../actions/changeAddressAction';
import { Place, PlaceType } from '../types/changeAddressTypes';

const defaultState = {
  hkAddressInput: {
    flat: '',
    block: '',
    floor: '',
    building: '',
    street: '',
    district: '',
    region: '',
  },
  MapAddressInput: {
    flat: '',
    block: '',
    floor: '',
    building: '',
    street: '',
    district: '',
    region: '',
  },
  goGreenList: [],
  isLoading: false,
  otherPolicyList: [],
  selectedList: [],
  address: {
    addrLine1: '',
    addrLine2: '',
    addrLine3: '',
    addrLine4: '',
    districtAddress: '',
    country: '',
  },
  form: {
    notify: null,
    useOfData: null,
    providedValidData: null,
  },
  transactionId: '',
  isShowAddressPopup: false,
  isConfirmLoadingButton: false,
  isHappyHKCase: false,
  isGoogleMapJsScriptComplete: false,
  isLoadingEditPage: false,
  isGoogleMapJsScriptError: false,
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case AUTO_COMPLETE_ADDRESS_CHANGED:
      return {
        ...nextState,
        hkAddressInput: { ...action.payload.placeData },
      };
    case AUTO_COMPLETE_MAP_ADDRESS_CHANGED:
      return {
        ...nextState,
        MapAddressInput: { ...action.payload.placeData },
      };
    case AUTO_COMPLETE_MAP_ADDRESS_CHANGED_CONFIRM:
      return {
        ...nextState,
        hkAddressInput: { ...nextState.MapAddressInput },
      };
    case UPDATE_ADDRESS_FORM_STATES:
      nextState.form = { ...nextState.form, ...action.payload };
      return nextState;
    case UPDATE_ADDRESS_GOGREEN_LIST:
      nextState.goGreenList = action.payload;
      return nextState;
    case FETCH_ADDRESS_OTHER_POLICY_LIST_SUCCESS:
      nextState.otherPolicyList = action.otherPolicyList;
      nextState.isLoading = false;
      return nextState;
    case FETCH_ADDRESS_OTHER_POLICY_LIST_FAIL:
      nextState.isLoading = false;
      return nextState;
    case UPDATE_SELECTED_LIST:
      return {
        ...nextState,
        selectedList: action.selectedList,
      };
    case UPDATE_ADDRESS_SAVE_FIELD:
      return {
        ...nextState,
        address: { ...action.payload },
      };
    case SUBMIT_CHANGE_ADDRESS_FORM:
      return {
        ...nextState,
        isLoading: true,
      };
    case SUBMIT_CHANGE_ADDRESS_FORM_SUCCESS:
      return {
        ...nextState,
        isLoading: false,
        transactionId: action.transactionId,
        transactions: action.transactions,
      };
    case SUBMIT_CHANGE_ADDRESS_FORM_FAILURE:
      return {
        ...nextState,
        isLoading: false,
      };
    case SET_UP_SHOW_ADDRESS_POPUP:
      return {
        ...nextState,
        isShowAddressPopup: action.payload.isShowAddressPopup,
      };
    case SET_UP_CONFIRM_LOADING_BUTTON:
      return {
        ...nextState,
        isConfirmLoadingButton: action.payload.isConfirmLoadingButton,
      };
    case SET_UP_IS_HK_ADDRESS_CASE:
      return {
        ...nextState,
        isHappyHKCase: action.payload.isHappyHKCase,
      };
    case UPDATELOAD_GOOGLE_MAP_JS_SCRIPT_STATUS:
      return {
        ...nextState,
        isGoogleMapJsScriptComplete: action.payload.isGoogleMapJsScriptComplete,
      };
    case UPDATE_EDIT_PAGE_LOADING:
      return {
        ...nextState,
        isLoadingEditPage: action.payload.isLoadingEditPage,
      };
    case AUTO_COMPLETE_ADDRESS_CHANGED_ENTER:
      nextState.hkAddressInput.building = action.payload.building;
      nextState.hkAddressInput.street = '';
      return nextState;
    case UPDATE_IS_GOOGLE_MAP_JS_SCRIPT_ERROR:
      nextState.isGoogleMapJsScriptError = action.isError;
      return nextState;
    default:
      return nextState;
  }
};

export function getPlaceName(placeData: Place, targetType: PlaceType) {
  const firstObj = placeData.address_components.find((v) =>
    v.types.includes(targetType),
  );
  return firstObj ? firstObj.long_name || firstObj.short_name : '';
}
