import _, { round } from 'lodash';
import { EmmaFactory } from '@axa-asia/hk-emma-js/dist';
import { getLanguage } from '../selectors/userSelectors';
import env from '../env';
import { LocalizedText, SingularPluralText } from '../types/LocalizedTextTypes';
import { select } from '../stores';
import { getToken } from '../selectors/authSelectors';
import jwtDecode from 'jwt-decode';
import sha1 from 'sha1';
import { getDeepLinkMapping } from '../selectors/configSelectors';
import moment from 'moment';
import { t } from '../locales';
import Cookies from 'cookies-js';
import $url from 'url';
import { skipSidebarToMyPrivileges } from '../actions/sideBarItemActions';
import { isTabletDevice } from '@axa-asia/hk-emma-web-module/lib/ga-lib/DeviceUtil';
import { SHA3 } from 'sha3';
import { getRandom } from './randomNumUtil';
import { isMobileRoute } from '../actions/appActions';
import {
  AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_EXERCISETIME,
  AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_LIGHTACTIVITYTIME,
  AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_SEDENTARYTIME,
  AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_SLEEPTIME,
  AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_STEPS,
  iconLightActivityPath,
  iconPageExercisePath,
  iconPageLightActivityPath,
  iconPageSedentaryPath,
  iconPageSleepPath,
  iconPageStepPath,
  iconSedentaryPath,
} from '../constants/constants';

export const __DEV__ = process.env.NODE_ENV === 'development';

export const createAction = (moduleName: string, actionType: string) => {
  return `${moduleName}/${actionType}`;
};

export const _get = <TState>(
  obj: any,
  path: string,
  defaultValue?: TState,
): TState => _.get(obj, path, defaultValue);

export function cleanObject(obj: { [prop: string]: any }) {
  const propNames = Object.getOwnPropertyNames(obj);
  for (let i = 0; i < propNames.length; i++) {
    const propName = propNames[i];
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
}

export const stringFormatter = (
  template: string,
  variables: { [name: string]: string },
) => {
  let result = template;
  Object.keys(variables).forEach((name) => {
    const expression = `\${${name}}`;
    const value = variables[name];
    while (result.indexOf(expression) >= 0) {
      result = result.replace(expression, value);
    }
  });

  return result;
};

export const clearEmptyParams = (url: string) =>
  url
    .replace(/[^?&\=\{\}]+\=\$\{[^&\=\{\}]+\}[\&]{0,1}/g, '')
    .replace(/&$/, '');

export const parseJSON = (input: string, resultRef: {}) => {
  try {
    const result = JSON.parse(input);
    _.merge(resultRef, result);
    return true;
  } catch (err) {
    return false;
  }
};

export const getValidToken = (token: string) => {
  try {
    let decoded: any = {};
    decoded = jwtDecode(token);
    if (!!decoded && !!decoded.sub) {
      return decoded.sub;
    } else {
      return '';
    }
  } catch (err) {
    return '';
  }
};

export const joinPath = (...paths: Array<string>) => {
  let joint = paths.filter((p) => p !== '/').join('/');
  joint = joint.replace('://', '__COLON__DOUBLE__SLASH__');
  joint = joint.replace(/\/+/g, '/');
  joint = joint.replace('__COLON__DOUBLE__SLASH__', '://');
  return joint;
};

export const getBasePathname = () => {
  if (!isMobileRoute()) {
    return joinPath(`/${getLanguage()}`, env.BASE_NAME);
  } else {
    return joinPath(`/${getLanguage()}`, `${env.BASE_NAME}/mob`);
  }
};

export const resolvePublicAssets = (relativePath: string) => {
  // CRA no longer used
  return joinPath(window.origin, relativePath);
  // PUBLIC_URL is a default config provided by React and used to get base path of public assets under different env.
  // return joinPath(process.env.PUBLIC_URL, relativePath);
};

export const resolveLargeImage = (imgPath: string) => {
  return resolvePublicAssets(imgPath)
    .replace('.png', '@3x.png')
    .replace('.jpg', '@3x.jpg');
};

export const isExternalAssets = (path: string) => {
  return path && path.includes('http');
};

export const isExternalLink = (path: string) => {
  return path && path.startsWith('http');
};

export const getBaseUrl = () => {
  return joinPath(window.location.origin, getBasePathname());
};

export const extractRelativeUrl = (pathname: string = getBasePathname()) => {
  let result = window.location.href.replace(window.location.origin, ``);
  result = result.replace(pathname, ``);

  if (result === '/') {
    result = '';
  }

  return result;
};

export const removeTrailingSlash = (url: string) => {
  return url.replace(/\/$/, ``);
};

export const sameRoute = (a = ``, b = ``) => {
  const aSegments = a
    .split(`/`)
    .filter((s) => !!s)
    .join(`/`);
  const bSegments = b
    .split(`/`)
    .filter((s) => !!s)
    .join('/');

  return aSegments === bSegments;
};

export const ngClass = (
  conditionalClassNames: { [className: string]: boolean },
  ...staticClassNames: Array<string>
) => {
  const classNames = Object.keys(conditionalClassNames);
  const filtered = classNames.filter((c) => conditionalClassNames[c]);

  return [...staticClassNames, ...filtered].join(` `);
};

export const resolveLocalizedText = (text: LocalizedText) => {
  if (!text) {
    return '';
  }
  if (typeof text === 'string') {
    return stringFormatter(text, { language: getLanguage() });
  } else {
    return text[getLanguage()];
  }
};

export const resolveSingularPlural = (
  value: number,
  text: SingularPluralText,
) => {
  if (typeof text === 'string') {
    return text;
  } else if (value > 1) {
    return text.plural;
  } else {
    return text.singular;
  }
};

export const getUniqueId = () => {
  const token = select(getToken);
  const decoded = jwtDecode(token) as any;
  const json = JSON.stringify(decoded);
  const hash = new SHA3(512);
  hash.update(json);
  const result = hash.digest('hex');
  // const result = sha1(json);
  return result;
};


/**
 * This function is only for web portal.
 * NOT for webview used by mobile.
 */
export const getGaClientId = () => {
  // const tcWindow: any = window;
  // if (
  //   tcWindow.tC &&
  //   tcWindow.tC.internalvars &&
  //   tcWindow.tC.internalvars.clientID
  // ) {
  //   return tcWindow.tC.internalvars.clientID;
  // }

  // GA4 Client ID - Retrieved from _ga Cookie
  const _ga = Cookies.get('_ga') ?? '';
  return _ga.replace(/^.*\.(?=.*\..*$)/g, '') ?? '';
};

export const resolveComplexDeepLink = (appDeepLink: string) => {
  const hasProtocol = appDeepLink.split(`://`).length > 1;
  if (!hasProtocol) {
    appDeepLink = `hkemmaapp://${appDeepLink}`;
  }

  const url = $url.parse(appDeepLink, true); // new URL(appDeepLink);

  const pathname = `${url.hostname}${url.pathname || ``}`; //(url.pathname || ``).replace(/^\/\//i, ``);
  const deepLinkMapping = select(getDeepLinkMapping);
  const complexDeepLink: any = deepLinkMapping[pathname];
  if (!complexDeepLink || typeof complexDeepLink === 'string') {
    return undefined;
  }
  complexDeepLink.payload = {
    ...(complexDeepLink.payload || {}),
    ...url.query,
  };
  return complexDeepLink;
};

export const resolveWebDeepLink = (appDeepLink: string) => {
  const hasProtocol = appDeepLink.split(`://`).length > 1;
  if (!hasProtocol) {
    appDeepLink = `hkemmaapp://${appDeepLink}`;
  }

  const url = $url.parse(appDeepLink, true); // new URL(appDeepLink);

  const pathname = `${url.hostname}${url.pathname || ``}`; //(url.pathname || ``).replace(/^\/\//i, ``);
  const deepLinkMapping = select(getDeepLinkMapping);
  const webTemplate = deepLinkMapping[pathname];
  if (!webTemplate) {
    return;
  }
  const matches = webTemplate.match(/\$\{[^{}]+\}/g) || [];
  const variableNames = matches.map((m) =>
    m.replace(/[\$\{\}]/g, ``).replace(/[\$\{\}]/g, ``),
  );

  const variables: { [name: string]: string } = {};
  variableNames.forEach((n) => {
    const queryParam = url.query[n];
    variables[n] =
      queryParam && Array.isArray(queryParam)
        ? queryParam.join(``)
        : queryParam;
  });

  const result = stringFormatter(webTemplate, variables).replace(/\/+$/, ''); // remove trailing slashes
  return result;
};

export const getGreetingMessage = () => {
  const currentTimeObj = moment();
  const currentTime = +moment(currentTimeObj).format('HH');
  let message = '';
  if (currentTime >= 5 && currentTime < 12) {
    message = t('GOOD_MORNING');
  } else if (currentTime >= 12 && currentTime < 18) {
    message = t('GOOD_AFTERNOON');
  } else {
    message = t('GOOD_EVENING');
  }
  return message;
};

export const getSessionId = () => {
  return Cookies.get(env.EMMA_SESSION_ID_COOKIE_NAME);
};

/**
 * Return "WEB" / "APP" / "TABLET"
 */
export const getPlatformStr = (): string => {
  if (isApp()) {
    return 'APP';
  } else if (isTabletDevice()) {
    return 'TABLET';
  } else {
    return 'WEB';
  }
};

export const isApp = () => {
  return hasEmmaUserAgent();
};

export const isIos = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const isIosSafariBrowser = () => {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  const isSafari = !!ua.match(/Safari/i);
  return iOS && webkit && isSafari && !hasEmmaUserAgent();
};

export const hasEmmaUserAgent = () => {
  const userAgent = navigator.userAgent;
  const regex = new RegExp(env.EMMA_APP_USER_AGENT_PATTERN, 'g');
  return regex.test(userAgent);
};

export const getPlainVersion = () => {
  const match = env.WEBAPP_VERSION.match(/^[0-9]+\.[0-9]+\.[0-9]+/);
  const webAppVersion = match ? match[0] : ``;
  return webAppVersion;
};

export const getBuildNumber = () => {
  const versionNumber = getPlainVersion()
    .split(`.`)
    .map((v) => v.padStart(2, `0`))
    .join(``);
  const match = env.WEBAPP_VERSION.match(/[0-9]+$/);
  const build = match ? +match[0] : 0;
  const buildNumber = `${build}`.padStart(3, `0`);

  return `${+versionNumber}${buildNumber}`;
};

export const removeQueryParameters = (...names: Array<string>) => {
  const url = new URL(window.location.href);
  const originalNames = Array.from(url.searchParams.keys());
  const queries: Array<string> = [];
  originalNames
    .filter((o) => names.indexOf(o) < 0)
    .forEach((o) => {
      queries.push(`${o}=${url.searchParams.get(o)}` || ``);
    });
  const queryString = queries.length > 0 ? `?${queries.join('&')}` : ``;
  const newUrl = `${url.origin}${url.pathname}${queryString}${url.hash}`;
  window.history.replaceState(null, '', newUrl);
};

export const leadingZero = (value: number, length = 2) => {
  return value.toString().padStart(length, '0');
};

export const setSkipSidebarToMyPrivileges = () => {
  const relativeUrl = extractRelativeUrl();
  //when user use link to go to myprivileges page
  if (relativeUrl.includes('myprivileges')) {
    skipSidebarToMyPrivileges();
  }
};

export function filterByRegex(collection: any, filterObj: any, selector: any) {
  return _.filter(collection, (rawItem) => {
    const item = selector ? _.get(rawItem, selector, {}) : rawItem;
    const result =
      !filterObj ||
      Object.keys(filterObj).every((conditionKey) => {
        if (
          (typeof item[conditionKey] === 'string' ||
            item[conditionKey] instanceof String) &&
          (typeof filterObj[conditionKey] === 'string' ||
            filterObj[conditionKey] instanceof String)
        ) {
          return new RegExp(filterObj[conditionKey]).test(item[conditionKey]);
        }
        return (
          item[conditionKey] && item[conditionKey] === filterObj[conditionKey]
        );
      });
    return result;
  });
}

function getAgeByBirth(brith: any) {
  if (!brith) return '';
  // const duration = moment.duration(moment().diff(brith));
  const years = moment().diff(brith, 'years');
  const months = moment().diff(brith, 'months');
  const days = moment().diff(brith, 'days');
  let result: any = '';
  if (years >= 1) {
    result = years * 12;
  } else {
    if (months > 0) {
      result = months;
    } else {
      result = 0;
    }
  }
  return result;
}

export const validateDateOfBirth = (
  year: any,
  month: any,
  date: any,
  minAge = 216,
  maxAge = 960,
) => {
  if (!date) return 'EMPTY_DATE_FIELD';
  if (!month) return 'EMPTY_MONTH_FIELD';
  if (!year) return 'EMPTY_YEAR_FIELD';
  const dob = moment(`${year}${month}${date}`, 'YYYYMMDD');
  if (!dob.isValid()) {
    return 'DATE_INVALID';
  }
  const ageResult = getAgeByBirth(dob);
  if (moment(dob) > moment()) {
    return 'BIRTHDATE_LESS_THAN_CURRENT_DATE';
  }
  if (minAge / 12 < 1) {
    if (ageResult < minAge) {
      return 'BIRTHDATE_LOW_THAN_6_MONTH';
    }
  }
  if (ageResult < minAge) {
    return 'BIRTHDATE_LOW_THAN_18';
  }
  if (ageResult > maxAge) {
    return 'BIRTHDATE_MORE_THAN_80';
  }
  return '';
};

export const isMobileWeb = () => {
  return document.body.offsetWidth < 769;
};

export const getLoginId = (token: string) => {
  const decoded = (token && jwtDecode(token)) || {};
  return _.get(decoded, 'loginId', '');
};

export const hideBackButton = () => {
  EmmaFactory.createInstance().then((p) => {
    if (p.instance.hideBackButton) {
      p.instance.hideBackButton();
    }
  });
};

export const isEnglishOrChinese = (str: string, lang = 'en') => {
  const l = lang.toLowerCase();

  if (l === 'zh') {
    return /^\p{Script=Hani}+$/gu.test(str);
  }
  return /^[A-Za-z][A-Za-z ]*[A-Za-z]$/u.test(str);
};

export const isEmail = (str: string) => {
  const regex =
    /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
  return regex.test(str);
};

export const getYouTubeVideoIdFromUrl = (url: string) => {
  // source: https://stackoverflow.com/a/5831191
  const regex =
    /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/gi;
  const videoId = url && url.match(regex) ? url.replace(regex, '$1') : ''; // first match group = video id

  return videoId;
};

export const getYouTubePlayerStates = () => {
  return {
    unstarted: -1,
    ended: 0,
    playing: 1,
    paused: 2,
    buffering: 3,
    videoCued: 5,
  };
};

export const getCategoryIconPath = (category: any) => {
  let iconPath: any;
  switch (category) {
    case AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_STEPS:
      iconPath = iconPageStepPath;
      break;
    case AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_SLEEPTIME:
      iconPath = iconPageSleepPath;
      break;
    case AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_EXERCISETIME:
      iconPath = iconPageExercisePath;
      break;
    case AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_LIGHTACTIVITYTIME:
      iconPath = iconPageLightActivityPath;
      break;
    case AKTIVO_INDIVIDUAL_CHALLENGE_CATEGORY_SEDENTARYTIME:
      iconPath = iconPageSedentaryPath;
      break;
    default:
      break;
  }
  return iconPath;
};

function numberWithCommas(x: any) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const formatToValue = (value: any, digit = 0) => {
  const price = value ? value : '';
  const priceNumType = parseFloat(price);
  if (!priceNumType || Number.isNaN(priceNumType)) return '--';
  return numberWithCommas('' + round(priceNumType, 2).toFixed(digit));
};

export const isChineseStr = (str: string) => {
  const re = /.*[\u4e00-\u9fa5]+.*$/;
  return re.test(str);
};

export const isOverLength = (str: string, length: number) => {
  return !!str && str.length > length;
};

export const isAllowCICAddressInput = (str: string) => {
  const re = /^[a-z0-9A-Z~#/.()\s']+$/;
  return re.test(str);
};

export const returnCICErrorMsg = (value: string, isRequired = false) => {
  let errorMsg = '';
  if (!!value && isChineseStr(value)) {
    errorMsg = t(`CUSTOMER_PROFILE_UPDATE_ADDRESS_INPUT_FIELD_EN`);
  } else if (!!value && !isAllowCICAddressInput(value)) {
    errorMsg = t(`CUSTOMER_PROFILE_UPDATE_ADDRESS_INPUT_FIELD_ALLOW_RULES`);
  }
  return errorMsg;
};

export const returnIsCICError = (value: string, isRequired = false) => {
  let isError = false;
  if (!!value && isChineseStr(value)) {
    isError = true;
  } else if (!!value && !isAllowCICAddressInput(value)) {
    isError = true;
  }
  return isError;
};
