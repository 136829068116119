import _ from 'lodash';
import {
  call,
  put,
  take,
  select,
  race,
  fork,
  takeLatest,
} from 'redux-saga/effects';
import {
  triggerPopupDisplayScreenChange,
  triggerPopupCloseScreenChange,
} from '@axa-asia/hk-emma-web-module/lib/ga-lib/GaPreviousScreenUtil';
import {
  SET_TNC_STYLE_MODEL,
  OPEN_CALL_SUPPORT_MODEL,
  OPEN_TNC_STYLE_MODEL,
  SET_CALL_SUPPORT_MODEL,
  OPEN_DIALOG,
} from '../actions/uiActions';
import { getDisclaimer } from '../modules/emma-common/selectors/ehubSelector';
import {
  Disclaimer,
  ContactDialog,
} from '../modules/emma-common/selectors/type';
import { isApp, resolveLocalizedText } from '../utils';
import history from '../stores/browserHistory';
import { getToken } from '../selectors/authSelectors';
import { getValidToken } from '../utils/index';
import {
  trackPartnerServiceDisclaimer,
  trackNavigationOverlayDisplay,
  handleGaError,
} from '../utils/generalGaEventAnalytics';
import base64url from 'base64url';
function* setTncStyleModel(action: any, isGoBack = false) {
  const { disclaimerId, isPreModel, modelData } = action.payload;
  let disclaimer: Disclaimer;
  if (modelData) {
    // modelData: not from profile-api
    disclaimer = modelData;
  } else {
    disclaimer = yield select(getDisclaimer, disclaimerId);
  }
  if (!disclaimer) {
    return;
  }
  const payload = {
    modalType: 'lockedModal',
    disclaimerId: disclaimerId,
    title: disclaimer.title ? resolveLocalizedText(disclaimer.title) : '',
    body: disclaimer.message ? resolveLocalizedText(disclaimer.message) : '',
    okButtonText: disclaimer.okButton
      ? resolveLocalizedText(disclaimer.okButton.label)
      : '',
    otherButtonText: disclaimer.otherButton
      ? resolveLocalizedText(disclaimer.otherButton.label)
      : '',
    otherDialog: {},
    okAction: () => {
      if (isGoBack) history.goBack();
      trackPartnerServiceDisclaimer(disclaimerId);
    },
    closeAction: () => {
      if (isGoBack) history.goBack();
    },
    otherAction: () => {
      if (isGoBack) history.goBack();
    },
    isPreModel: isPreModel,
  };
  yield put({
    type: OPEN_TNC_STYLE_MODEL,
    payload: payload,
  });
}

function* setContactCallModel(action: any, isGoBack = false) {
  const { disclaimerId, modelData, onCloseCallBack } = action.payload;
  let modelDataTmp: ContactDialog = {};
  if (disclaimerId) {
    modelDataTmp = yield select(getDisclaimer, disclaimerId);
  } else if (Object.keys(modelData).length) {
    modelDataTmp = modelData;
  }

  if (!Object.keys(modelDataTmp).length) {
    return;
  }

  const bodyContentTmp = [];

  if (modelDataTmp.bodyContent) {
    modelDataTmp.bodyContent.forEach((item: any) => {
      bodyContentTmp.push({
        label: resolveLocalizedText(item.label),
        value: item.value,
        hotlineAndWhatsapp: item.hotlineAndWhatsapp,
        link: item.link ? resolveLocalizedText(item.link) : '',
        notes: item.notes ? resolveLocalizedText(item.notes) : '',
        email: item.email || '',
      });
    });
  }

  const payload = {
    modalType: 'lockedModal',
    title: modelDataTmp.title ? resolveLocalizedText(modelDataTmp.title) : '',
    subTitle: modelDataTmp.subTitle
      ? resolveLocalizedText(modelDataTmp.subTitle)
      : '',
    bodyContent: bodyContentTmp,
    okButtonText: modelDataTmp.okButton
      ? resolveLocalizedText(modelDataTmp.okButton.label)
      : '',
    otherButtonText: modelDataTmp.otherButton
      ? resolveLocalizedText(modelDataTmp.otherButton.label)
      : '',
    otherDialog: {},
    okAction: () => {
      if (isGoBack) history.goBack();
    },
    closeAction: () => {
      if (isGoBack) history.goBack();
    },
    otherAction: () => {
      if (isGoBack) history.goBack();
    },
    onCloseCallBack: onCloseCallBack,
  };
  yield put({
    type: OPEN_CALL_SUPPORT_MODEL,
    payload: payload,
  });
}

function* trackOpenDialogGa(action: any): any {
  const { disclaimerId } = action.payload;

  if (disclaimerId) {
    const tcWindow: any = window;
    const token = yield select(getToken);
    const aToken = getValidToken(token);
    try {
      if (aToken) {
        // mock page redirection for GA
        tcWindow.dataLayer.push({
          event: 'screenView',
          page: `emma-common/transitionModal/${disclaimerId}`,
          userId: aToken,
        });

        // HKEMMA-100917 - GA3 Decommission - Remove GA3
        // if (!isApp()) {
        //   tcWindow.tC.event.virtualPageview(tcWindow, {
        //     page: `emma-common/transitionModal/${disclaimerId}`,
        //     AXAID: aToken,
        //   });
        // }
      }
      triggerPopupDisplayScreenChange(disclaimerId);
    } catch (e) {
      handleGaError(e);
    }
  }
  yield;
}

function* uiSaga() {
  yield takeLatest(SET_TNC_STYLE_MODEL, setTncStyleModel);
  yield takeLatest(SET_CALL_SUPPORT_MODEL, setContactCallModel);
  yield takeLatest(OPEN_DIALOG, trackOpenDialogGa);
}

export default uiSaga;
