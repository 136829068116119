import config from '../package.json';

const ID = `${config.name}/actions/chatbotActions`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const FETCH_MENU_INFO = '../../../modules/chatbot/FETCH_MENU_INFO';
export const FETCH_MENU_INFO_SUCCESS =
  '../../../modules/chatbot/FETCH_MENU_INFO_SUCCESS';

export const fetchHasVhisFlag = () => ({
  type: FETCH_MENU_INFO,
});

export const SHOW_CHATBOT = createActionName('SHOW_CHATBOT');
export const showChatbot = () => ({
  type: SHOW_CHATBOT,
});
export const HIDE_CHATBOT = createActionName('HIDE_CHATBOT');
export const hideChatbot = () => ({
  type: HIDE_CHATBOT,
});

export const FETCH_SPEECH_BUBBLE_SUCCESS = createActionName(
  'FETCH_SPEECH_BUBBLE_SUCCESS',
);
export const FETCH_SPEECH_BUBBLE_FAILURE = createActionName(
  'FETCH_SPEECH_BUBBLE_FAILURE',
);
export const RECORD_CLOSED_SPEECH_BUBBLE = createActionName(
  'RECORD_CLOSED_SPEECH_BUBBLE',
);
export const UPDATE_CLOSED_SPEECH_BUBBLE = 'UPDATE_CLOSED_SPEECH_BUBBLE';
export const FETCH_CLOSED_SPEECH_BUBBLE_CUST_STATE_SUCCESS =
  'FETCH_CLOSED_SPEECH_BUBBLE_CUST_STATE_SUCCESS';
export const FETCH_CLOSED_SPEECH_BUBBLE_CUST_STATE_FAILURE =
  'FETCH_CLOSED_SPEECH_BUBBLE_CUST_STATE_FAILURE';
export const SET_CLOSED_SPEECH_BUBBLE_CUST_STATE =
  'SET_CLOSED_SPEECH_BUBBLE_CUST_STATE';
export const recordCloseSpeechBubble = (
  sbId: string,
  closeMode: string,
  sbDetails: any,
) => ({
  type: RECORD_CLOSED_SPEECH_BUBBLE,
  payload: {
    sbId,
    closeMode,
    sbDetails,
  },
});
