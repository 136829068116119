import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@axa-asia/emma-stylesheet/lib/components/Modal';
import './styles.scss';
import HomePagePopUpRegistry from '../../../registries/HomePagePopUpRegistry';
import { hideModal } from '../../../modules/emma-modal/actions';

interface ActionModalViewInterface {
  journeyName: any;
  titleBoldText: any;
  subtitle: any;
  blueButtonText: any;
  whiteButtonText: any;
  gaEnabled: any;
  gaScreenName: any;
  blueButtonGaText: any;
  whiteButtonGaText: any;
  blueButtonWebAction: any;
  whiteButtonWebAction: any;
  blueButtonIsCallJourneyEnd: any;
  whiteButtonIsCallJourneyEnd: any;
  isGrayAreaClose: any;
  isGrayAreaCallJourneyEnd: any;
  blueButtonCloseModal: any;
  whiteButtonCloseModal: any;
}

interface ActionModalViewProps {
  buttonAction: (action: any, props: any) => void;
  payload: any;
  hideDialog: () => void;
}

interface ActionModalViewState {}

class ActionModalView extends Component<
  ActionModalViewProps,
  ActionModalViewState
> {
  constructor(props: ActionModalViewProps) {
    super(props);
    this.state = {};
    this.closeModal = this.closeModal.bind(this);
    this.blueButtonClicked = this.blueButtonClicked.bind(this);
    this.whiteButtonClicked = this.whiteButtonClicked.bind(this);
    this.grayAreaClicked = this.grayAreaClicked.bind(this);
  }

  closeModal() {
    this.props.hideDialog();
  }

  blueButtonClicked() {
    const {
      blueButtonWebAction,
      blueButtonIsCallJourneyEnd,
      journeyName,
      blueButtonCloseModal,
    } = this.props.payload;
    if (blueButtonCloseModal) {
      this.closeModal();
    }
    if (blueButtonWebAction) {
      this.props.buttonAction(blueButtonWebAction, this.props);
    }
    if (blueButtonIsCallJourneyEnd === true && journeyName) {
      HomePagePopUpRegistry.setIsShowPopupCompleted(journeyName);
    }
  }

  whiteButtonClicked() {
    const {
      whiteButtonWebAction,
      whiteButtonIsCallJourneyEnd,
      journeyName,
      whiteButtonCloseModal,
    } = this.props.payload;
    if (whiteButtonCloseModal) {
      this.closeModal();
    }
    if (whiteButtonWebAction) {
      this.props.buttonAction(whiteButtonWebAction, this.props);
    }
    if (whiteButtonIsCallJourneyEnd === true && journeyName) {
      HomePagePopUpRegistry.setIsShowPopupCompleted(journeyName);
    }
  }

  grayAreaClicked() {
    const { isGrayAreaClose, isGrayAreaCallJourneyEnd, journeyName } =
      this.props.payload;
    if (isGrayAreaClose === true) {
      this.closeModal();
    }
    if (isGrayAreaCallJourneyEnd === true && journeyName) {
      HomePagePopUpRegistry.setIsShowPopupCompleted(journeyName);
    }
  }

  render() {
    const type = 'emma';
    const {
      titleBoldText,
      localImagePath,
      subtitle,
      blueButtonText,
      whiteButtonText,
    } = this.props.payload;

    return (
      <Modal
        show={true}
        onHide={this.closeModal}
        onClick={this.grayAreaClicked}
        className={'emma-stylesheet-modal-container-share'}
      >
        <ModalHeader
          className={'emma-stylesheet-modal-header-share'}
          onClick={(event: any) => {
            event.stopPropagation();
          }}
        >
          <div className="emma-share-body-main-content-share-title">
            {titleBoldText}
          </div>
        </ModalHeader>
        <ModalBody
          className={'emma-stylesheet-modal-body-share'}
          onClick={(event: any) => {
            event.stopPropagation();
          }}
        >
          <div className="emma-share-body-main-content-share">
            <div
              className="emma-share-body-main-content-share-subtitle"
              dangerouslySetInnerHTML={{ __html: subtitle || '' }}
            ></div>
          </div>
        </ModalBody>
        <ModalFooter
          onClick={(event: any) => {
            event.stopPropagation();
          }}
        >
          <div className="emma-modal-footer-main-content-share">
            <button
              className="emma-stylesheet-primary-default-button emma-stylesheet-primary-default-button-share"
              onClick={this.blueButtonClicked}
              data-enable-ga
              data-ga-type="button"
            >
              {blueButtonText}
            </button>
            <button
              className="emma-stylesheet-primary-ghost-button emma-stylesheet-primary-ghost-button-share"
              onClick={this.whiteButtonClicked}
              data-enable-ga
              data-ga-type="button"
            >
              {whiteButtonText}
            </button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }

  static mapStateToProps(state: any) {
    return {};
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      hideDialog: () => {
        dispatch(hideModal());
      },
      buttonAction: (action: any, props: any) => {
        dispatch({ type: action, payload: props });
      },
    };
  }
}

export default connect(
  ActionModalView.mapStateToProps,
  ActionModalView.mapDispatchToProps,
)(ActionModalView);
